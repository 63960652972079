export default {
  cardTrait: [
    'Labourer, General Construction Operative',
    'Carpenter and Joiner',
    'Painter and Decorator',
    'Bricklayer',
    'Construction Contracting Surveyor',
    'Plumber',
    'Plasterer: Fibrous',
    'Plasterer: Solid',
    'Roofer - Specialist Leadworker',
    'Architect',
    'Construction Team Leader',
    'Construction Operative Kerbs & Channels',
    'Kitchen Bathroom Fitter',
    'Steelfixer',
    'Wall and Floor Tiler',
    'Civil Engineer',
    'Dry Liner: Fixer/ Finisher',
    'Construction Project Manager',
    'Construction Operative - Excavation and Reinstantement',
    'Glazier',
    'Construction Operative - Structural Concrete',
    'Construction Operative Concrete Repairer',
    'Construction Operative Drainage Construction',
    'Construction Operative Drainlayer',
    'Demolition Manager',
    'Demolition Operative (Topman)',
    'Demolition Site Operative (Trainee)',
    'Demolition, Reclaimation and Refurbishment Operative',
    'Diamond Driller and Sawer',
    'External Plasterer',
    'Industrial Cleaner',
    'Service Duct Layer',
    'Specialist Operative - Sprayed Concrete',
    'Specialist Operative: Concrete Repairer',
    'Specialist Operative: Drainlayer',
    'Specialist Operative: Highways Maintenance',
    'Steel Erector',
    'Steel Fabricator - Welder',
    'Steel Fabricator - Plater',
    'Tunneller - Hand Miner',
    'Tunneller - Shaft Miner',
    'Tunnelling Machine Operator',
    'Clerk of Works',
    'Construction Contracting Buyer',
    'Construction Contracting Planner',
    'Construction Health and Safety Officer',
    'Construction Site Manager (Contracting)',
    'Cable TV System Installer',
    'Construction Site Supervisor (Contracting)',
    'Chainman',
    'Engineer (Trainee)',
    'Process Plant & Equipment Installer',
    'Welding Inspector',
    'Asbestos Removal Operative',
    'Builders Cleaner',
    'Cavity Wall Insulation Technician',
    'Ceiling Fixer',
    'Cleaner',
    'Damp Proofing Operative',
    'Highways Maintenance - Excavation & Reinstatement',
    'Highways Maintenance Operative: Drainage',
    'Highways Maintenance Operative: Flexible Pavement Construction',
    'Highways Maintenance Operative: Kerbs and Channels',
    'Highways Maintenance Operative: Modular Pavement Construction',
    'Highways Maintenance Operative: Modular Paving and Kerb Laying',
    'Highways Maintenance Operative: Shallow Drainage and Ducting Installation',
    'Highways Maintenance Operative: Structural Concreting',
    'Highways Maintenance: Shallow Drainage Installation Operative',
    'Joint Taper',
    'Landscape Operative - Exterior',
    'Landscape Operative - Interior',
    'Landscaper',
    'Lift Maintenance Fitter',
    'Lift Installer',
    'On-site Steel Welder',
    'Piling Operative',
    'Plant Mechanic/ Construction Plant Maintenance and Repair',
    'Railway Engineer',
    'Road Builder - High Friction Surfacing Operative - Manual',
    'Road Builder - High Friction Surfacing Operative - Machine',
    'Road Builder - Retexturing',
    'Road Building: Planer',
    'Road Recycling Operative',
    'Roof Slater and Tiler - New Roof',
    'Roof Slater and Tiler - Re Roof',
    'Service Lift Engineer',
    'Shopfitter Sitefixer',
    'Site Logistics - Plant',
    'Stud Welder',
    'Telecoms Cable Layer',
    'Tower Crane Erector',
    'Wet Pour Rubber Surface Operative',
    'Wood Machinist',
    'Building Control Surveyor',
    'Building Maintenance & Estates Supervisor',
    'Contracts Manager',
    'Demolition Engineer',
    'Property Manager',
    'Quantity Surveyor',
    'Senior Construction Site Manager',
    'Surveying Assistant (General Practice)',
    'Transportation Assistant',
    'Access Flooring Operative',
    'Aerial and Satellite Installer',
    'Aerial Rescue',
    'AHU Installer',
    'Air Compressor Engineer',
    'Air Testing of Buildings Operative',
    'Aluminium TIG Welding & Fabrication',
    'Amenity Manager',
    'Amenity Supervisor',
    'Amenity Worker',
    'Ansell Fire Suppression System Installer',
    'Antenna Systems Installer',
    'Anti Graffiti Finishing Operative',
    'Arborist Manager',
    'Arborist Supervisor',
    'Arborist Worker - Chainsaw',
    'Archaeologist',
    'Archaeologist',
    'Archaeologist Technician',
    'Architect',
    'Architectural Lighting Control Systems Installer',
    'Architectural Metalwork Paint Sprayer',
    'Architectural Technologist',
    'Asbestos Analyst - 4 Stage Clearance',
    'Asbestos Analyst - Asbestos Air Sampling',
    'Asbestos Analyst - Bulk Sampling',
    'Asbestos Inspector/ Surveyor',
    'Asbestos Removal Operative',
    'Asbestos Removal Supervisor',
    'Autoclaved Aerated Concrete Frame Erector',
    'Automated People Mover Installer',
    'Automatic Irrigation System Installer',
    'Automatic Monitoring & Surveying Installer',
    'Automatic Welding Process Operative',
    'Axle Weighbridge Installer',
    'Bench Joiner',
    'Blast Cleaning Contractor',
    'Blind and Shutter Installer',
    'Bricklayer',
    'Bricklayer',
    'Brise Soleil/Louvre Installer',
    'Building Control Officer',
    'Building Maintenance & Estates Manager',
    'Building Maintenance & Estates Supervisor',
    'Building Management Control Systems Engineer',
    'Building Site Manager (Conservation)',
    'Building Surveying Assistant',
    'Building Surveying Assistant',
    'Built Up Felt Roofer',
    'Butt Fusion Operative',
    'Cabinet Maker & Polisher',
    'Capacitor Equipments Installer & Maintenance Operative',
    'Capital Equipment Installer',
    'Car Park Deck Waterproofing Operative',
    'Carpenter and Joiner',
    'Cathodic Protection Engineer',
    'Cathodic Protection Snr Technician',
    'Cathodic Protection Technician',
    'Cavity Wall Tie Replacement Operative',
    'CCTV Surveys of Drainage/Pipeline Systems Operative',
    'Chainsaw Maintenance and Cross Cutting only',
    'Chemical Cleaning Specialist',
    'Chemical Grouting Operative',
    'Chimney Engineer',
    'Chlorination & Disinfection Equipment Installer',
    'Civil & Structural Engineering Designer',
    'Civil Engineer',
    'Civil Engineering Technician',
    'Cleaning/ Flushing & Chlorination Operative',
    'Clerk of Works',
    'Cold Form Steel Frame Erector',
    'Commissioning / Servicing & Installation of Water System Engineer',
    'Commissioning Assistant',
    'Communications Technology Manager',
    'Communications Technology Supervisor',
    'Compressed Air Engineer & Pipe fitter',
    'Conservation Consultant',
    'Conservation Controller',
    'Conservation Technician',
    'Conservator',
    'Conservator',
    'Construction Built Environment Planner',
    'Construction Contracting Buyer',
    'Construction Contracting Buying Assistant',
    'Construction Contracting Buying Assistant',
    'Construction Contracting Estimating Assistant',
    'Construction Contracting Estimating Assistant',
    'Construction Contracting Estimator',
    'Construction Contracting Planning Assistant',
    'Construction Contracting Planning Assistant',
    'Construction Contracting Surveying Assistant',
    'Construction Contracting Surveying Assistant',
    'Construction Health and Safety Manager',
    'Construction Health and Safety Officer',
    'Construction Health and Safety Senior Manager',
    'Construction Materials Test Technician',
    'Construction Operative Spray Concretor',
    'Construction Operative Structural Concretor',
    'Construction Project Manager',
    'Construction Site Manager (Conservation)',
    'Construction Site Manager - Residential',
    'Construction Site Manager - Sustainability',
    'Construction Site Supervisor (Contracting)',
    'Construction Site Supervisor (Highways Maintenance)',
    'Construction Site Supervisor (Highways Maintenance)',
    'Construction Site Supervisor - (Conservation)',
    'Construction Site Supervisor - (Conservation)',
    'Construction Site Supervisor - (Tunnelling)',
    'Construction Site Supervisor - Residential',
    'Construction Site Supervisor - Residential',
    'Control Panel Installer',
    'Control Panel Manufacturer',
    'Conveyor Belt Operative',
    'Coving Installer',
    'Craft Mason',
    'Damp Proofer/ Wood Preserver',
    'DC Power Equipment Installer',
    'Decorative Artist',
    'Decorative Artist',
    'Demolition Supervisor',
    'Dental Equipment Service & Installation Engineer',
    'Directional Driller',
    'Dishwasher Conveyor System Installer',
    'Disinfection System Installer',
    'Display Cabinet Installer',
    'Domestic Appliance Installer',
    'Door Systems Engineer - Installation',
    'Door Systems Engineer - Installation & Repair',
    'Door Systems Engineer - Repair',
    'Drainage Maintenance & Surveying Operative',
    'Dry Liner: Finisher',
    'Dry Liner: Fixer',
    'Dust Extraction Systems Installer',
    'Eaves & Rainwater System Installer',
    'Electrical & Electronic Security Operative',
    'Electrical Co-ordination Draughtsman',
    'Electrical Control Engineer',
    'Electrical Control Panel Installer',
    'Electrical Distribution Switchgear Installer',
    'Electrical Generator Installer',
    'Electrical Installation & Distribution Operative',
    'Electrical LV Equipment Installer',
    'Electrical Solutions Installer',
    'Electrical Switchgear Installer',
    'Electrically Powered Mechanical Plant Installer',
    'Electrostatic Paint Sprayer',
    'Emergency Power Systems Installer',
    'Energy Saving Systems Installer/Electrician',
    'Engineering Surveyor',
    'Engineering/Electrical Contractor',
    'Entrance Matting & Matwell Framing Installer',
    'Environmental Conservator (Rivers, Coasts and Waterways)',
    'Environmental Manager (Construction)',
    'Escalator Installer',
    'External Wall Insulation Installer',
    'Fabric and Membrane Cladding Installer',
    'Facade Maintenance: Cleaner',
    'Facade Maintenance: Surface Repairer',
    'Facade Technician',
    'Facilities Management Manager',
    'Facilities Management Operative',
    'Facilities Management Supervisor',
    'Fascia, Soffit and Bargeboard Installer',
    'Fence Installer - Vehicle Safety',
    'Fenestration Supervisor',
    'Fenestration Surveyor',
    'Fire Curtain Installer',
    'Fire Extinguisher Engineer',
    'Fire Resistant Glazier',
    'Fire/Smoke Ventilation Installer',
    'Flood Lighting System Installer',
    'Floorcoverer: Resilient/ Impervious',
    'Floorcoverer: Textiles',
    'Floorcoverer: Timber',
    'Flue Fitter',
    'Formworker',
    'French Polisher',
    'Fridge & Freezer Installer',
    'Fuel Tank, Pipe work, Fuel Pump Installer',
    'Fume Cupboard Installer',
    'Furniture Installer',
    'Garage Equipment Installer',
    'Gas Catering Equipment Installer',
    'Gas Fitters, Appliance Installer',
    'Gas Mains & Service Layer',
    'Gas Membrane Installer',
    'Gas Pipelines Installer (Medical)',
    'Gas Vent & Chimney Systems Installer',
    'General Highways Maintenance Operative',
    'Ground Anchor Installer',
    'Ground Anchor Installer',
    'Handrail/Balustrade/Staircase Installer',
    'Haulier',
    'Health Physic Surveyor',
    'Heating & Frost Prevention Installer',
    'Heating & Frost Prevention Installer',
    'Heavy Jacking System Operative',
    'Heavy Timber Frame Carpenter',
    'Heritage Skills - Blacksmith',
    'Heritage Skills - Bricklayer',
    'Heritage Skills - Carpenter and Joiner',
    'Heritage Skills - Craft Mason',
    'Heritage Skills - Earth Waller',
    'Heritage Skills - Fibrous Plasterer',
    'Heritage Skills - Finisher',
    'Heritage Skills - Hard Metal Roofer',
    'Heritage Skills - Memorial Mason',
    'Heritage Skills - Painter and Decorator',
    'Heritage Skills - Roof Slater and Tiler',
    'Heritage Skills - Solid Plasterer',
    'Heritage Skills - Specialist Leadworker',
    'Heritage Skills - Stone Carver',
    'Heritage Skills - Stone Mason Banker',
    'Heritage Skills - Stone Mason Banker Fixer',
    'Heritage Skills - Stone Mason Fixer',
    'Heritage Skills - Stone Mason Marble and Granite',
    'Heritage Skills - Stone Mason Setter Out',
    'Heritage Skills - Wall and Floor Tiler',
    'Highway Amenity & Car Park Lighting Installer',
    'Hire Desk Operative',
    'Hire Desk Supervisor',
    'Hoist Installer',
    'Hospital/Nursing Home Bathing Equipment Installer',
    'HVCA Controls Engineer',
    'HVCA Controls Panel Design Manufacturer, Service & Commission Engineer',
    'Hydraseeding, Weed Control Operative',
    'Hydraulic Bridge Systems Installer',
    'Hydraulic Pump Installer',
    'ICQ Operative - Pharmaceutical Equipment',
    'In Situ Flooring: Cementitous Screeding',
    'In Situ Flooring: Concrete',
    'In Situ Flooring: Resin Coating',
    'In Situ Flooring: Resin Screed',
    'In Situ Flooring: Resin Self Smoothing',
    'Incident Support Unit Operative',
    'Industrial & Domestic Drain & Pipe Cleaning/Repair Service/Drain Relining Operative',
    'Industrial Boiler Erector',
    'Industrial Coatings Applicator',
    'Industrial Coatings Applicator',
    'Industrial Control Systems Engineer',
    'Industrial Gas Commissioning Engineers',
    'Industrial Storage Equipment Installer',
    'Installation Co-ordinator',
    'Installation Engineer: Mobile Conductors',
    'Installer of Storage Equipment',
    'Installer: Conservatories',
    'Installer: Curtain Waller',
    'Installer: Windows and Doors',
    'Installing & Commissioning Humidifiers Operative',
    'Instrument Technician',
    'Insulated Panel Installer - (Insulated Enclosures - Commercial)',
    'Insulated Panel Installer - (Insulated Enclosures - Industrial)',
    'Internal Insulation Operative',
    'Invasive Weed Surveyor',
    'Laboratory Technician',
    'Land Drilling - Driller',
    'Land Drilling - Lead Driller',
    'Land Surveyor',
    'Landscape Architect',
    'Landscape Manager',
    'Landscape Supervisor',
    'Large Electrical Power Distribution Switchboard Manufacturer/Installer',
    'Laundry Equipment Installer',
    'Lift & Escalator Manager',
    'Lift & Escalator Supervisor',
    'Lift/Escalator Tester',
    'Lifting Equipment & Gear Installer',
    'Light Control System Installer',
    'Light Steel Frame Erector',
    'Lightning Conductor Engineer',
    'Lightning Protective Systems Inspector and Tester',
    'Liquid Waterproofing Operative',
    'Loading Bay & Elevating Equipment Engineer',
    'Loading Bay Equipment Installer',
    'Loft Insulation Installer',
    'Louvre & Smoke Ventilation Installer',
    'Louvre and Diffuser Cleaning Operative',
    'Louvre Grills Installer',
    'Machine Applied Plasterer',
    'Machine Tunnelling Operative',
    'Mains Rehabilitation & Multi Utility Operations Engineer',
    'Maintenance Operative',
    'Masonry Work Operative',
    'Mastic Asphalter',
    'Mechanical Fitter',
    'Mechanical Fitter, Sheet Metal Worker & Welder',
    'Medical & Industrial Gas Pipeline Installer',
    'Medical and Laboratory Gas System Installer',
    'Medical Equipment Installer',
    'Medical Gas Technician',
    'Modular Buildings Installer/ Dismantler',
    'Multi Fuel Cooker Installer',
    'Non Hazardous Waste Removal Operative',
    'Occupational Work Supervisor',
    'On-Site Repair/Spray Composite Cladding Panels',
    'Operable Partitioner',
    'Painter and Decorator Industrial',
    'Panel Wireman',
    'Partition Fixer/ Demountable Partitioner',
    'Passive Fire Protection',
    'Personal Fall Protection Installer - Permanent Solutions',
    'Pesticides Foreman',
    'Pesticides Manager',
    'Pipefreezing - Pressure Grouting Operative',
    'Pipefreezing and Isolation Service Operative',
    'Pipeline Intervention/Hot Tapping Technician',
    'Pipeline Renovator',
    'Plant Mechanic/ Construction Plant Maintenance and Repair',
    'Plasterer: Fibrous',
    'Plasterer: Solid',
    'Platform Lift Installer',
    'Portable Building Installer/ Dismantler',
    'Post Tensioner Operative',
    'Power System Installer',
    'Precast Concrete Installer',
    'Principal Utilities Mapping Surveyor',
    'Process & Control Eng Water Treatment Operative',
    'Process Commissioning Engineer',
    'Process, Design Engineer of Water/Waste Water Projects',
    'Property and Caretaking Supervisor',
    'Public Address System Installer',
    'Public Utilities Electricity Cable Layer',
    'Quality Manager',
    'Quantity Surveying Assistant',
    'Quantity Surveyor',
    'Radiant Panel Installer',
    'Railway Engineer - Track Maintenance',
    'Rain Water Goods Installer',
    'Remedial Treatment Surveyor',
    'Remediation Technician',
    'Repair Technician (External/Internal Scrapes, Dents & Chips to buildings)',
    'Resin & Grout Injection Operative',
    'Road Builder - Soil Stabilisation',
    'Road Building: Bituminous Pavior',
    'Road Building: Concrete Pavior',
    'Road Building: Micro Asphalter',
    'Road Building: Pavement Marking Hand',
    'Road Building: Pavement Marking Machine',
    'Road Building: Pavement Marking Stud',
    'Road Building: Surface Dresser',
    'Road Pavement Consulting Engineer',
    'Roof Line System Installer',
    'Roof Sheeter and Cladder',
    'Roof Slater and Tiler',
    'Roof Slater and Tiler - New Roof',
    'Roof Slater and Tiler - Re Roof',
    'Rubber/PVC Linings Water Storage Tanks Installer',
    'Safety Net Rigger',
    'Sanitary Ware Repairer',
    'Sauna/Steam Room & Spa Installer',
    'Sealant Application Supervisor',
    'Sealant Applicator',
    'Security System Installer',
    'Senior Transportation Practitioner',
    'Senior Utilities Mapping Surveyor',
    'Separation Plant Operative',
    'Shopfitter Benchwork',
    'Shotfiring Operator',
    'Shower/Steam Cubicles & Whirlpool Installer',
    'Sign Installer - Illuminated',
    'Sign Installer - Non Illuminated',
    'Sign Maintenance/Repair Operative',
    'Single Ply Roofer',
    'Site Logistics - General',
    'Site Technical Assistant',
    'Smoke & Natural Ventilation Installer',
    'Soil & Groundwater Remedition Systems Installer',
    'Soil Aeration and Decompaction Technician',
    'Spatial Data Manager',
    'Specialist Access Engineering & Maint. Installer',
    'Specialist Cladding and Rainscreen Operative',
    'Specialist Operative - Sprayed Concrete',
    'Specialist Operative: Concrete Placer',
    'Specialist Operative: General',
    'Specialist Operative: Land Drilling',
    'Specialist Operative: Pavior',
    'Specialist Operative: Piling',
    'Specialist Operative: Spray Concretor',
    'Specialist Operative: Tunnelling',
    'Specialist Rendering Operative',
    'Specialist Restorer: Historic Sturctures',
    'Specialist Restorer: Historic Sturctures',
    'Specialist Vacuum Sewage System Installer',
    'Specialist Water Treatment Operative',
    'Specialist Water/Sewer Rehab Operative',
    'Spoil Removal Equipment Operative',
    'Sprayed Concrete Lining Tunnelling Operative',
    'Stair Tower Operative',
    'Stairlift Engineer',
    'Steeplejack',
    'Sticker Operative (Plaster Board/Ceiling)',
    'Stone Cladding Installer',
    'Stone Fixer',
    'Stonemason: Banker',
    'Stonemason: Banker',
    'Stonemason: Banker/ Fixer',
    'Stonemason: Banker/ Fixer',
    'Stonemason: Fixer',
    'Stonemason: Fixer',
    'Stonemason: Marble and Granite',
    'Stonemason: Setter Out',
    'Street Lighting Installer',
    'Structural Post and Beam Carpenter',
    'Structural Repairer - Reinforcement',
    'Sub-Structure Operative - Preformed Piles',
    'Sub-Structure Operative - Retaining Structures',
    'Surveying Assistant (General Practice)',
    'Suspended Access Equipment Installer',
    'Swimming Pool Installer',
    'Syphonic Drainage System Installer',
    'Tacker Operative (Plaster Board)',
    'Tank Cleaner',
    'Technical Designer (Built Environment)',
    'Temporary Excavation Support Operative',
    'Temporary Industrial Rope Access Installer',
    'Temporary VRS Installer',
    'Temproary Traffic Management Operative',
    'Terrazzo, Marble, Granite Installer',
    'Timber Frame Erector',
    'Tinsmith',
    'Town Planning Assistant',
    'Town Planning Practitioner',
    'Trace Heating System Installer',
    'Train Cleaning Equipment Installer',
    'Transportation Practitioner',
    'Tunnel Transport Operative',
    'Tunneller',
    'Tunneller - Machine Miner',
    'Tunneller - NATM Miner',
    'Tunneller - Pipejacking/Micro Tunnelling Miner',
    "Tunnelling Operative - Electrician's Mate",
    'Tunnelling Services Operative',
    'Underground Mapping Operative',
    'Underpinner',
    'Upholsterer',
    'Utilities Mapping Surveyor',
    'Utilities Mapping Technician',
    'Value Actuator Commissioning & Repair Operative',
    'Velocity Patcher',
    'Video Surveillance Operator/Installer',
    'Wall and Floor Tiler',
    'Washroom Systems Installer',
    'Waste Handling Installer',
    'Waste Management Operative',
    'Waste/Water Treatment Operative',
    'Water & Sewage Industry Engineer',
    'Water Cleaner/Descaler Installer',
    'Water Feature Installer',
    'Water Filtration/Treatment Engineer',
    'Water Flow Control & Regulation Technician',
    'Water Hygiene & Maintenance Technician',
    'Water Jetter - Cutting & Breaking',
    'Water Jetter - Drains and Sewers',
    'Water Jetter - Surface Cleaning',
    'Water Jetter - Tube Cleaning',
    'Water Leak Detection Systems Installer',
    'Water Leakage Testing Technician',
    'Water Mains Layer',
    'Water Meter Installer & General Plumbing Engineer',
    'Water Park Equipment Installer',
    'Water Sculpture Installer',
    'Water Services Layer',
    'Water Sewage Penstock Installer',
    'Water Storage Tank Erector',
    'Water Systems, Safety Cabinet, Incubator Installer',
    'Water Treatment Commissioning Engineer',
    'Water Treatment Equipment Installer',
    'Water, Petro Chemical & Process Industry Installer',
    'Welding Examiner',
    'Window Opening/Closing Equipment Installer',
    'Wood Machinist',
    'Wood Preserver/ Damp Proofer',
    'Work Surface Installer',
  ],
};
