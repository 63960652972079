export default {
  occupationWithTest: [
    'A/claved Concreteframe Erection',
    'Abrasion And Corrosive Resistant Linings Installer',
    'Academically Qualified Person',
    'Access Flooring Operative',
    'Access Systems Installer',
    'Acoustic & Vibration Control Installer',
    'Acoustic Consulting Engineer',
    'Acoustic Floor Installer',
    'Acoustic Package Installer',
    'Aerial/satellite Installer',
    'Agricultural Tractor Driver, Strimmer Operator',
    'Agricultural/quarry Fence Erector',
    'Air Mat - Fall Arrest Systems Installer',
    'Air Tightness Testing (c)',
    'Aluminium Installer',
    'Aluminium Louvres, Screens & Acoustic Enclosures Installer',
    'Aluminium Towers & Access Equipment Inspector',
    'Aluminium/pvc Entrance Matting Installer',
    'Ansul Fire Suppression System Installer',
    'Antenna Systems Installer',
    'Anti Graffiti Finishing Operative',
    'Arboriculture & Forestry Operative',
    'Archaeological Technician',
    'Archaeologist',
    'Archaeologist Technician',
    'Arches & Cut Brick Specialist',
    'Architects Registration Board',
    'Architectural & Decorative Installer ',
    'Architectural Metalwork Installer',
    'Architectural Metalwork Paint Sprayer',
    'Architectural Metalworker',
    'Architectural Technologist',
    'Archivist',
    'Artificial/synthetic Surface Installer',
    'Asbestos Analyst',
    'Asbestos Analyst',
    'Asbestos Inspector/ Surveyor',
    'Asbestos Removal Operative',
    'Asphalt Or Bituminous Macadam Surfacing Specialist',
    'Assistant Land Surveyor',
    'Association For Project Safety',
    'Association Of Project Management',
    'Atm Installation Operative',
    'Automated Barrier Installer',
    'Automated Gate Installer',
    'Automated People Mover Installer',
    'Automatic Irrigation System Installer',
    'Banker Masonry',
    'Bath & Sanitary Ware/surface Repairer',
    'Bathroom Fitter',
    'Bench Joinery',
    'Bird Deterrent Systems Installer',
    'Blast Cleaning Contractor',
    'Blind & Shutter Installer',
    'Bolted Steel Tank Erector',
    'Brick Tinter Operative',
    'Bricklaying',
    'Brickwork Restraint Installer',
    'Brise Soleil/louvre Installer',
    'British Computer Society',
    'British Institute Of Facilities Management ',
    'British Institute Of Interior Design (biid)',
    'British Institute Of Non-destructive Testing',
    'British Institute Of Non-destructive Testing (binstndt)',
    'British Society Of Soil Science (bsss)',
    'Builders Cleaner',
    'Building And Construction (aqp)',
    'Building Condition Surveyor',
    'Building Control Officer',
    'Building Control Surveyor',
    'Building Control Technical Support',
    'Building Maintenance & Estates Manager',
    'Building Maintenance & Estates Supervisor',
    'Building Site Manager',
    'Building Site Supervisor',
    'Building Surveying Assistant',
    'Building Surveyor',
    'Built Up Felt Roofing',
    'Burner',
    'Butt Fusion Operative',
    'Cabinet Maker & Polisher',
    'Cable Fall Arrest Restraint Systems Installer',
    'Cable Percussive Drilling Engineer/engineering Geologist',
    'Canal/waterway Conservator',
    'Car Park Deck Waterproofing Operative',
    'Car Parking Equipment Installer',
    'Carpentry And Joinery',
    'Carpet Tile Fitter ',
    'Catering And Sanitary Equipment Installer',
    'Catering Equipment Installer (non Sanitary)',
    'Cathodic Protection Senior Technician',
    'Cathodic Protection Technician',
    'Cavity Barrier Installer',
    'Cavity Wall Insulation Technician',
    'Cavity Wall Tie Replacement Operative',
    'Cctv Drainage',
    'Ceiling Fixing',
    'Ceiling Hoists Shower/bath Product Installer',
    'Chainman',
    'Chainsaw Maintenance And Cross Cutting Only',
    'Chainsaw Operative',
    'Chainsaw, Tree Climbing',
    'Chartered Association Of Building Engineers',
    'Chartered Institute For Archaeologists',
    'Chartered Institute Of Building',
    'Chartered Institute Of Ecology And Environmental Management',
    'Chartered Institute Of Housing',
    'Chartered Institute Of Logistics And Transport (cilt)',
    'Chartered Institute Of Plumbing And Heating Engineering',
    'Chartered Institution Of Architectural Technologists',
    'Chartered Institution Of Civil Engineering Surveyors',
    'Chartered Institution Of Highways & Transportation',
    'Chartered Institution Of Water And Environmental Management',
    'Chartered Quality Institute (cqi)',
    'Chartered Society Of Designers (csd)',
    'Checkout Service Operative',
    'Chemical Grouting Operative',
    'Chimney Engineers',
    'Chimney Sweeper',
    'Ciob (aqp)',
    'Civil And Structural Engineering Design',
    'Civil Engineering (aqp)',
    'Civil Engineering Site Manager',
    'Civil Engineering Site Supervisor',
    'Civil Engineering Technician',
    'Cladding Panel Installer',
    'Cladding/fascia Installer',
    'Cleaner',
    'Cleaner, Diamond Floor Polisher',
    'Cleaning & Decontamination Operative',
    'Coating & Lining Specialist',
    'Cob Builder',
    'Cold Formed Steel Frame Erection',
    'Cold Roof Insulation Installer',
    'Cold/warm Roof Insulation Operative',
    'Commercial Catering Equipment Installer',
    'Commercial Kitchen Equipment Fitter',
    'Commercial Kitchen Installer',
    'Composite Metal Decking Installer',
    'Computer Systems Installer',
    'Cone Penetration Test Operative',
    'Confined Space Rescue Operative',
    'Conservation Controller',
    'Conservation Technician',
    'Construction Built Environment Planner',
    'Construction Buyer',
    'Construction Contracting',
    'Construction Diver',
    'Construction Estimator',
    'Construction Fixings Installer And Tester',
    'Construction Materials Test Technician',
    'Construction Operations Concrete Repair',
    'Construction Operations Drainage Construction',
    'Construction Operations Kerb & Channel Layer',
    'Construction Operations Reinstatement',
    'Construction Operations Structural Concrete',
    'Construction Operative Excavation',
    'Construction Operative Excavation & Reinstatement',
    'Construction Operative Non Structural Concreting',
    'Construction Planner',
    'Construction Plant & Equipment Manager',
    'Construction Plant & Equipment Supervisor',
    'Construction Plant Maintenance & Repair',
    'Construction Project Manager',
    'Construction Site Cleaner',
    'Construction Site Engineering Technician',
    'Construction Site Inspection Manager',
    'Construction Site Inspector',
    'Construction Site Manager - Tunnelling',
    'Construction Site Manager Conservation',
    'Construction Site Manager Construction Engineering',
    'Construction Site Manager Contracting',
    'Construction Site Manager Highways Maintenance & Repair',
    'Construction Site Manager Residential',
    'Construction Site Manager Sustainability',
    'Construction Site Supervision Conservation',
    'Construction Site Supervision Tunnelling',
    'Construction Site Supervisor Contracting',
    'Construction Site Supervisor Highways Maintenance',
    'Construction Site Supervisor Residential',
    'Construction Site Visitor',
    'Construction Surveying Assistant',
    'Construction Surveyor',
    'Construction Team Leader',
    'Contracts Manager',
    'Control Systems Engineer',
    'Controlled Environment Construction Specialist',
    'Conveyor Belt Operative',
    'Cooker Pot Man',
    'Corian Fabricator & Installer',
    'Cornish Hedge Builder',
    'Cosmetic Repairer',
    'Coving Installer',
    'Craft Mason',
    'Crane Installer',
    'Crane Rail Systems Installer',
    'Cubicle/locker Installer',
    'Curtain And Blind Installer',
    'Curtain,blind & Soft Furnishings Installer',
    'Curtain/blind Site Installation Operative',
    'Custodial Equipment Manufacturer/installer',
    'Custodial Equipment Manufacturer/installer',
    'Decommissioning Operative',
    'Decorative Artist - Non Construction',
    'Demolition',
    'Demountable Partitioning',
    'Design Co-ordinator',
    'Design, Manufacture, Install Lift Cars, Architraves, Lift Safety Decking Installer',
    'Design, Supply & Install Hydraulic Bridge Systems Installer',
    'Dewatering Operative Well Points',
    'Diamond Drilling And Sawing (dsa)',
    'Directional Drilling Operative',
    'Display Cabinet Installer',
    'Diving & Underwater Engineering Projects Engineer',
    'Domestic Appliance Installation & Maintenance Operative',
    'Domestic Roof Ventilation Installer',
    'Door & Ironmongery Fitter',
    'Door System Engineer Installation',
    'Door System Engineer Installation & Repair',
    'Door System Engineer Repair',
    'Draught Proofing Installer',
    'Dredging And Marine Contractor',
    'Drinks Equipment Installer',
    'Drive Systems Manufacturer & Installer',
    'Drone Operator',
    'Dry Lining Boarder',
    'Dry Stone Waller',
    'Dry Stone Waller',
    'Dryliner Finisher',
    'Dryliner Fixer',
    'Dryliner Fixer/finisher',
    'Dynamic Pile Testing And Inspection',
    'Eaves/rainwater Goods Installer',
    'Ecologist',
    'Ecologist',
    'Edge Protection Operative',
    'Edge Protection Operative (faset)',
    'Electric Security Fence Installer',
    'Electrical And Electronic (aqp)',
    'Electricity Cable Layer',
    'Electro-fusion Operative',
    'Electronic Point Of Sale (epos) It Systems ',
    'Electronic Testing Of Waterproofing Operative',
    'Electrostatic Paint Sprayer',
    'Energy Institute',
    'Engineer Ireland',
    'Engineering Equipment Installer',
    'Engineering Services (aqp)',
    'Engineering Surveyor',
    'Entrance Matting And Matwell Framing Installer',
    'Environmental Manager Construction',
    'Environmental Practitioner',
    'Epoxy Resin & Polyester Glassfibre Reinforced Lining Systems Installer',
    'Equipment Service Engineer',
    'Erection Of Parapet Railings Installer',
    'Escalator Installer',
    'Explosive Ordnance Clearance (eoc) Engineer',
    'External Renderer - Manual',
    'External Renderer Machine Applied',
    'External Stone Fixer',
    'External Wall Insulation - Boarder',
    'External Wall Insulation Operative',
    'Extractives Driller',
    'Extrusion Of Asphalt Kerb Slipform Pave Operative',
    'Fabricator Of Glass Supporting Systems (manufacturing)',
    'Fabrics Structure Installer',
    'Facade Maintenance Cleaning',
    'Facade Maintenance Surface Repairer',
    'Fall Arrest System Installer',
    'Fall Arrest Technician',
    'Fibrous Plastering',
    'Fire Curtain Installer',
    'Fire Engineer',
    'Fire Prevention & Sprinkler System Installation & Maintenance Fitter',
    'Fire Protection Specialist',
    'Fire Surround Installer',
    'Fixer Masonry',
    'Flexible Bound Aggregate Surfacing (non Bituminous)',
    'Flexiwall/partition Installer',
    'Floor Flatness (surveying, Remedial Grinding, Floor Joint Repairs) Operative',
    'Floor Insulation Installer',
    'Floorcoverer Textiles',
    'Floorcoverer Textiles And Resilient',
    'Floorcoverer Timber',
    'Floorcovering Resilient/impervious',
    'Floorcovering Textiles',
    'Floorcovering Timber',
    'Floorlight Installer (non Electrical)',
    'Foamed Concrete Operative',
    'Folding/sliding Door Installer (non-acoustic)',
    'Formwork Carpenter',
    'Formwork Erector/striker',
    'Formworking',
    'French Polisher',
    'Furnace Reline Operative',
    'Furniture Installer',
    'Gabion Wall Installer',
    'Gaming And Leisure Equipment Installer',
    'Garage Equipment Installer',
    'Gas Engine Service Engineer',
    'Gas Engineer',
    'Gas Mains & Service Layer',
    'Gas Membrane Installer',
    'Gas Protection Verification Scheme',
    'Gas/containment Membrane Installer',
    'Gate & Barrier Installer',
    'General Highways Maintenance',
    'Generator Mechanic/installer',
    'Geological Society',
    'Geophysical Logging Engineer',
    'Geosynthetic Installer',
    'Geotechnical Specialist',
    'Glass Block Walling Systems Installation',
    'Glass Fibre Insulation Installer',
    'Glass In-situ Restoration Specialist',
    'Glass Partition/internal Screen Systems Installer',
    'Glass Reinforced Plastic Installer (wet Laid)',
    'Glass Smoke Screen Installer',
    'Glazing Inspection Operative',
    'Graffiti Removal Operative',
    'Grass & Wildflower Seeding Operative',
    'Green Roof Installer',
    'Ground Anchor Installer',
    'Ground Gas Protection Validator',
    'Ground Specialist',
    'Grouting Operative (mine Workings)',
    'Grp Products Installer',
    'Guardrail System Installer',
    'Gym & Sports Equipment Installer',
    'Handrail/balustrade/staircase Installer',
    'Haulier',
    'Health & Safety Manager Construction',
    'Health & Safety Officer Construction',
    'Health, Safety, Environmental Or Quality (aqp)',
    'Heating & Frost Prevention Installer',
    'Heating Fitter',
    'Heavy Jacking System Operative',
    'Heavy Timberframe Carpenter',
    'Heritage Blacksmith',
    'Heritage Brick Worker',
    'Heritage Carpentry & Joinery',
    'Heritage Façade Preservation',
    'Heritage Fibrous Plastering',
    'Heritage Hard Metal Roofer',
    'Heritage Mason',
    'Heritage Metal Roofer',
    'Heritage Painter/decorator',
    'Heritage Roof Slating & Tiling',
    'Heritage Skills',
    'Heritage Skills - Glazier',
    'Heritage Skills Craft Mason',
    'Heritage Skills Stone Carver ',
    'Heritage Skills Stonemason Banker/fixer',
    'Heritage Solid Plastering',
    'Heritage Specialist Leadworker',
    'Heritage Stonemason - Fixer',
    'Heritage Thatching',
    'Heritage Wall & Floor Tiling',
    'Hetas Installer',
    'Hgv Mechanic/fitter',
    'Highway Maintenance Operative - Jet Patcher Only',
    'Highway Maintenance Operative Natural Stone',
    'Highways Maintenance Concreting',
    'Highways Maintenance Drainage',
    'Highways Maintenance Drainage & Ducting',
    'Highways Maintenance Excavation',
    'Highways Maintenance Excavation & Reinstatement',
    'Highways Maintenance Flexible Pavement',
    'Highways Maintenance Kerbs & Channels',
    'Highways Maintenance Modular & Flexible Pavement',
    'Highways Maintenance Modular Pavement',
    'Highways Maintenance Operative Non Structural Concreting',
    'Highways Maintenance Shallow Drainage Operative',
    'Hire Desk Operative',
    'Hire Desk Supervisor',
    'Hoist Installer',
    'Hoist Installer',
    'Horticultural Service Engineer',
    'Hospital Furniture Installer',
    'Hot Melt Rubberised Bitumen Operative',
    'Hydraseeding, Weed Control Operative',
    'Hydraulic Pump Installer',
    'Hygienic Cladding Installer',
    'Icq Operative - Pharmaceutical Equipment',
    'Ict (aqp)',
    'In Situ Flooring Cementitious Screeding',
    'In Situ Flooring Concrete',
    'In Situ Flooring Concrete Plant Operator',
    'In Situ Flooring Resin Coating',
    'In Situ Flooring Resin Screed',
    'In Situ Flooring Resin Self Smoothing',
    'In-situ Flooring - Resin',
    'Incident Support Unit Operative',
    'Inductive Loops & Intercoms Installer',
    'Industrial Cleaner',
    'Industrial Coatings Applicator',
    'Industrial Door Installer ',
    'Industrial Rope Access',
    'Industrial Services Operative Damage Repair & Resurfacing',
    'Industry Placement',
    'Installation Co-ordinator',
    'Installation Engineer: Mobile Conductors',
    'Installer Of Storage Equipment',
    'Institute Of Acoustics',
    'Institute Of Asphalt Technology (iat)',
    'Institute Of Carpenters',
    'Institute Of Clerks Of Works And Construction Inspectorate',
    'Institute Of Concrete Technology',
    'Institute Of Conservation',
    'Institute Of Environmental Management And Assessment',
    'Institute Of Fire Safety Managers (ifsm)',
    'Institute Of Healthcare Engineering & Estate Management',
    'Institute Of Highways Engineers',
    'Institute Of Historic Building Conservation (ihbc)',
    'Institute Of Leadership And Management (ilm) (aqp)',
    'Institute Of Maintenance And Building Management',
    'Institute Of Marine Engineering, Science And Technology',
    'Institute Of Materials, Minerals And Mining',
    'Institute Of Measurement And Control',
    'Institute Of Physics',
    'Institute Of Physics & Engineering In Medicine',
    'Institute Of Roofing',
    'Institute Of Theatre Consultants',
    'Institute Of Workplace And Facilities Management',
    'Institution Of Agricultural Engineers',
    'Institution Of Chemical Engineers',
    'Institution Of Civil Engineers',
    'Institution Of Engineering And Technology',
    'Institution Of Engineering Designers',
    'Institution Of Environmental Sciences',
    'Institution Of Fire Engineers',
    'Institution Of Gas Engineers And Managers',
    'Institution Of Lighting Professionals',
    'Institution Of Mechanical Engineers',
    'Institution Of Occupational Safety & Health',
    'Institution Of Railway Signal Engineers',
    'Institution Of Royal Engineers',
    'Institution Of Structural Engineers',
    'Instrument And Control Engineer',
    'Insulated Enclosures Commercial',
    'Insulated Enclosures Industrial',
    'Insulated Frame Installer',
    'Interior Fixer And Sheeter',
    'Internal Insulation Operative',
    'Internal Stone Fixer',
    'International Institute Of Risk And Safety Management ',
    'Invasive Weed Surveyor',
    'Ionising Radiation Specialist',
    'Joint Taper',
    'Kitchen Fitter',
    'Kitchen/bathroom Fitter',
    'Laboratory & Scientific Equipment Engineer',
    'Laboratory Assistant',
    'Laboratory Assistant Non-destructive Testing',
    'Laboratory Technician',
    'Labourer',
    'Land Driller Support Operative',
    'Land Drilling',
    'Land Drilling Lead Driller',
    'Land Remediation Specialist',
    'Land Surveyor',
    'Landscape Institute',
    'Landscape Irrigation System Installer',
    'Landscaper',
    'Lead Worker/sheet Weathering Installer',
    'Leadworker',
    'Leak Detection Waterproof Systems',
    'Lift And Escalator Support Services Operative',
    'Lift Car Interiors & Architectural Finishes Specialist',
    'Lift Installer',
    'Lift Maintenance Fitter',
    'Lift Refurbishment (non Mechanical) Operative',
    'Lift/escalator Tester',
    'Lifting Equipment Engineer',
    'Lifting Equipment Installer (shaft & Machine Room Works)',
    'Light Steel Frame Erector',
    'Lightning Conductor Engineer',
    'Lightning Protective Systems Inspector/tester',
    'Liquid Waterproofing Operative',
    'Loading Bay And Elevating Equipment Engineer',
    'Loading Bay Equipment Installer',
    'Loading Bay Equipment Installer',
    'Locksmith',
    'Loft Insulation Installer',
    'Loft Ladder Installer',
    'Logistics Operative',
    'Machine Tunnelling Operative',
    'Machinery Maintenance Operative',
    'Maintenance Operations',
    'Maintenance Operative',
    'Maintenance Operative General ',
    'Manager Lift & Escalator',
    'Manhole Surveyor',
    'Marble & Mosaic Specialist',
    'Marine Construction Operative',
    'Marquee Erector',
    'Mason Banker/fixer',
    'Mason Pavior',
    'Masonry Work Operative',
    'Mastic Asphalting',
    'Materials Test Technician',
    'Mechanical & Electrical Baggage Handling Installer',
    'Mechanical Fitter',
    'Mechanical Installation Fitter',
    'Mechanical Mobile Engineer (paving & Compaction Equip.)',
    'Mechanical Pipefitting',
    'Memorial Mason',
    'Metal Decking Installer',
    'Metal Fabricator (not Engineering Construction)',
    'Metal Framing System Installer',
    'Metal Framing Systems Installer',
    'Metal Polisher',
    'Metal Shelving Installer',
    'Metal Stairways, Platforms, Flooring Operative',
    'Metal Window & Door Maintenance Operative',
    'Mezzanine Floor Installer',
    'Micro Surfacing Operative',
    'Mining Surveyor',
    'Mobile Access Tower Installer',
    'Mobile Building Remover',
    'Modular Buildings Installer/dismantler',
    'Modular Pontoon Installer',
    'Move Manager (relocation)',
    'Nebosh Construction (aqp)',
    'Non Destructive Test Inspector',
    'Non-destructive Test Technician',
    'Non-hazard Waste Removal Op',
    'Nrc Event Rigger',
    'Nuclear Institute ',
    'Occupational Work Supervisor',
    'On-site Aluminium Welder',
    'On-site Sprayer/powder Coat & Anodise Repairer',
    'On-site Steel Welder',
    'Onsite Repair/spray Composite Cladding Panels',
    'Operable Partitioner',
    'Ornamental & Architectural Woodcarver',
    'Overhead Crane Installer',
    'Overhead Patient Hoist Installer',
    'Painter And Decorator Industrial',
    'Painting And Decorating',
    'Parapet Installer',
    'Passive Fire Protection',
    'Pavement Marking Operative',
    'Pavement Marking Operative Hand',
    'Pavement Marking Operative Machine',
    'Pavement Marking Operative Stud',
    'Pavementlight Installer (non Electrical)',
    'Pedestrian & Vehicular Access Control Engineer',
    'Perimeter Heating Installer',
    'Personal Fall Protection Installer Permanent Solutions',
    'Pest Control Operative',
    'Pest Controller',
    'Photovoltaic Panel Installer',
    'Physical Security Equipment Installer',
    'Pile Integrity & Pile Load Testing Operative',
    'Piling Operative',
    'Pipejacking/micro Tunneller',
    'Plant Operations Paver',
    'Plant Operator',
    'Plastering',
    'Plastic Fabricator',
    'Plastic Welder',
    'Platform Deck Installer (faset)',
    'Platform Lift Installer',
    'Playground Equipment Installer',
    'Plumbing',
    'Plumbing & Mechanical Engineering Services Engineer',
    'Pmes System Tank Installer',
    'Point Of Purchase Installer',
    'Portable Cabins Installer/dismantler',
    'Portable Sanitation Installer',
    'Portable Temporary Pump Engineer',
    'Post Tensioning Operative',
    'Precast Concrete Installer',
    'Principal Utilities Mapping Surveyor',
    'Property & Caretaking Supervisor',
    'Property Maintenance Operative ',
    'Property Manager',
    'Property Services (aqp)',
    'Protection Guidance Checkout Installer',
    'Protective Coating Specialist',
    'Provisional (temporary Card)',
    'Public Seating System Installer',
    'Pump Installation & Maintenance Fitter',
    'Pump Out Equipment Supplier & Installer (marinas)',
    'Pump Service Engineer',
    'Pvc Hygenic Wall Cladding Installer',
    'Qualified Assessor',
    'Qualified External Verifier',
    'Qualified Internal Verifier',
    'Quality Manager Construction',
    'Quantity Surveying Assistant',
    'Quantity Surveyor',
    'Racking & Shelving Installer',
    'Radiation Shielding Specialist',
    'Radio Communications Systems Installer',
    'Radiographic Materials Testing Engineer',
    'Railway Engineer',
    'Railway Engineer Permanent Way Maintenance',
    'Railway Engineer Track Maintenance',
    'Rainwater Systems & Roof Drainage Installer',
    'Recreational Floor Coatings Operative',
    'Refuse Chute Systems Installer',
    'Reinforced Steel Installer/erector',
    'Remedial Treatment Surveyor',
    'Renewable Energy Systems Installer',
    'Repair Technician (internal/external Scrapes, Dents & Chips To Buildings)',
    'Residential Site Manager',
    'Residential Site Supervisor',
    'Resin & Grout Injection Operative',
    'Resin Injection & Grouting Operative',
    'Road Builder - High Friction Surfacing',
    'Road Builder - Retexturing',
    'Road Builder - Soil Stabilisation',
    'Road Pavement Consulting Engineer',
    'Road Recycling Operative',
    'Road Sweeper And Gully Sucker Operator',
    'Roadbuilding - High Friction Surfacing Operative - Machine',
    'Roadbuilding - High Friction Surfacing Operative - Manual',
    'Roadbuilding Crack & Joint Repair Operative',
    'Roadbuilding Geosynthetic (membrane/steel Mesh) Installer',
    'Roadbuilding Operative - Resin Bonded',
    'Roadbuilding Operative - Resin Bound Surfacing (manual)',
    'Roadbuilding Operative: Concrete Paving',
    'Roadbuilding Operative: Micro Asphalt',
    'Roadbuilding Operative: Planing',
    'Roadbuilding Operative: Surface Dressing',
    'Roadbuilding Operative:bituminous Paving',
    'Roadbuilding Slurry-microsurfacing Machine',
    'Roadbuilding Slurry-microsurfacing Operative - Manual',
    'Roadbuilding Surface Dressing Machine',
    'Roadbuilding Thermal Repair Operative',
    'Roadsweeper & Gullysucker Operator',
    'Rock Drilling Anchorage Cliff Stabiliser Installer',
    'Roller Shutter Installer',
    'Roof Line System Installer',
    'Roof Sheeting And Cladding',
    'Roof Slater',
    'Roof Slater & Tiler New Roof',
    'Roof Slater & Tiler Re-roof',
    'Roof Slater And Tiler',
    'Roof Slating And Tiling',
    'Roof Tiler',
    'Roof Tiler - New House Build',
    'Roofer Specialist Leadworker',
    'Rooflight/glazing System Installer',
    'Roughcast Installer',
    'Royal Aeronautical Society',
    'Royal Incorporation Of Architects In Scotland',
    'Royal Institute Of British Architects',
    'Royal Institution Of Chartered Surveyors',
    'Royal Institution Of Naval Architects',
    'Safe/vault Installer',
    'Safety Anchor System Installer',
    'Safety Life Line And Anchorage Installer',
    'Safety Net Rigger (faset)',
    'Safety Surfacing (rubber) Operative',
    'Sales Negotiator/ Advisor',
    'Scaffold Alarm Engineers',
    'Scaffolder',
    'Scaffolding Alarm Installer',
    'Sealant Application Supervisor',
    'Sealant Applicators',
    'Secondary Glazing Installer',
    'Security Guard',
    'Senior Construction Health & Safety Manager',
    'Senior Construction Site Manager',
    'Senior Transportion Practitioner',
    'Senior Utilities Mapping Surveyor',
    'Separation Plant Operative',
    'Service Duct Layer',
    'Service Layer (water)',
    'Service Lift Engineer',
    'Sewage & Water Treatment Equipment Installer',
    'Sewage Pump Service Engineer',
    'Sewage Screen Installer',
    'Sewage Treatment Mechanical Installer',
    'Sewage Treatment Plant Pumping Station Installer',
    'Sewage Treatment Plant Service Engineer',
    'Sewage/water Treatment Operative',
    'Sewer & Drainage Maintenance Operative',
    'Shelving And Checkout Installer',
    'Shopfitting Bench Work',
    'Shopfitting Sitefixing (c)',
    'Sign Installer Illuminated',
    'Sign Installer Non-illuminated',
    'Sign Maintenance/repair Operative',
    'Single Ply Roofing',
    'Site Engineer',
    'Site Logistics General',
    'Site Logistics Plant',
    'Site Nurse',
    'Site Technical Assistant',
    'Slipform Rig Operator',
    'Slipform Rig Operator',
    'Smoke Outlet Panel Installer',
    'Society Of Operations Engineers',
    'Software Engineer',
    'Soil & Groundwater Remediation Systems Installer',
    'Soil Aeration & Decompaction Technician',
    'Solid Plastering',
    'Solid Surface Fabricator',
    'Sound Engineer',
    'Spatial Data Manager',
    'Specialist Access Engineering & Maint. Installer',
    'Specialist Cladding & Rainscreen Operative',
    'Specialist Cladding Installer',
    'Specialist Clean Room Installer',
    'Specialist Concrete Operative',
    'Specialist Hospital Equipment Installer',
    'Specialist Medical Pendant & Theatre Light Installer',
    'Specialist Multisensory Equipment Installer',
    'Specialist Operations Concrete Repair',
    'Specialist Operations Highways Maintenance',
    'Specialist Operations Land Drilling',
    'Specialist Operations Mason Pavior',
    'Specialist Operations Spray Concrete',
    'Specialist Operative General',
    'Specialist Rendering Operative',
    'Specialist Restorer: Historic Structures',
    'Specialist Seating & Tiering Installer',
    'Specialist Surgical Installer',
    'Specialist Wall Tiler Only',
    'Speech Transfer Units & Induction Loops Manufacturer/installer',
    'Spoil Removal Equipment Operative',
    'Sports Floor Installer',
    'Sports Hall Court Marker',
    'Sports Pitch Construction Operative',
    'Sprayed Concrete Lining Tunnelling Operative',
    'Stage Equipment Installer',
    'Stair Tower Installer (faset)',
    'Stair Tower Operative',
    'Stairlift Engineer',
    'Static Insitu Testing Operator',
    'Steam Cleaning Operative',
    'Steel And Timber Garden Centre Erector',
    'Steel Cutter',
    'Steel Decker',
    'Steel Erector',
    'Steel Fabricator Plater',
    'Steel Fabricator Welder',
    'Steelfixing',
    'Steeplejack',
    'Steeplejacking',
    'Sterilisation/removal Pigeon Foul Operative',
    'Sticker Operative (plaster Board/ceiling)',
    'Still Worker Operative',
    'Stone Cladding Installer',
    'Stone Fixer',
    'Stone Mason - Stone Cutter',
    'Storage Equipment Installer',
    'Storage Tank Installer',
    'Storefitter',
    'Storeman',
    'Street Furniture Operative',
    'Stretched Ceiling Installer',
    'Structural Bonding Operative',
    'Structural Glazier',
    'Structural Post And Beam Carpenter',
    'Structural Repair And Stabilisation Operative',
    'Structural Repairer',
    'Structural Repairer Reinforcement',
    'Structural Repairs & Treatment Pressure Grouting Operative',
    'Structural Waterproofing & Concrete Repairer',
    'Structural Waterproofing Operative',
    'Structural Waterproofing Surveyor',
    'Stud Welder',
    'Sub Floor Preparation Operative',
    'Sub-structure Operative Preformed Piles',
    'Sub-structure Operative Retaining Structures',
    'Substrate Preparation And Profiling ',
    'Supervisor Lift/escalator',
    'Supply, Install & Maintain Kitchen Ventilated Ceilings Operative',
    'Surface Dressing Operative',
    'Surface Treatment Specialist',
    'Surveying Assistant General',
    'Suspended Access Equipment Installer',
    'Suspended Ceiling Installer',
    'Swimming Pool & Leisure Systems Installer',
    'System Disinfection & Chlorination Operative',
    'Systems (metal) Erector',
    'Systems Integrator',
    'Tacker Operative (plaster Board)',
    'Tank Cleaner',
    'Tank Erection Glass Fused Installer',
    'Tank Erector',
    'Technical Design Built Environment',
    'Technical Specialist - Transmissions & Drivelines',
    'Telecommunications Cable Layer',
    'Teleflex Morse Windows Installer',
    'Temporary Industrial Rope Access Installer',
    'Temporary Roadway/fencing/access Installer',
    'Temporary Traffic Management',
    'Temporary Vrs Installer',
    'Terrazzo Installer',
    'Textured Wall/ceiling Finisher',
    'Thatching',
    'The Institute Of Specialist Surveyors And Engineers (isse)',
    'Thermal Insulation - Fabrication Protection',
    'Thermal Insulation - Fit Protection',
    'Thermal Insulation Installers',
    'Thermal Insulation Supervisor/manager',
    'Thin Joint Masonry Systems',
    'Through Deck Stud Welder',
    'Thrust Boring Operative',
    'Timber Decking Installer',
    'Timber Frame Designer',
    'Timber Frame Erector',
    'Timber Frame Support Operative',
    'Timber Hoarding Installer',
    'Timber Restorer',
    'Timing Systems Installer',
    'Tinsmith',
    'Tool Hire Delivery Driver',
    'Tool Hire Representative',
    'Tower Crane Erector',
    'Tower Crane Erector',
    'Town Planning Assistant',
    'Town Planning Practitioner',
    'Transportation Assistant',
    'Transportation Practitioner',
    'Tree & Vegetation Clearance Operative',
    'Tree Surgeon',
    'Trolley Parks & Guidance Protection Equipment Installer',
    'Tunnel Transport Operative',
    'Tunneller',
    'Tunneller Hand Miner',
    'Tunneller Machine Miner',
    'Tunneller Natm Miner',
    'Tunneller Shaft Miner',
    'Tunneller Tunnel Miner',
    'Tunnelling Machine Operator',
    'Tunnelling Operative Fitters/electricians Mate',
    'Tunnelling Services Operative',
    'Tyre Fitter',
    'Ukas (aqp)',
    'Underground Mapping Technician',
    'Underpinning',
    'Underwater Inspections, Construction & Demolition Specialist',
    'Upvc Roofline Product Installer',
    'Utilities Mapping Supervisor',
    'Utilities Mapping Surveyor',
    'Utilities Mapping Technician',
    'Vacuum Excavation Operative',
    'Validation Engineer (clean Rooms & Clean Air Equip.)',
    'Vehicle Cleaning Equipment Installer',
    'Vehicle Lift Engineer',
    'Vehicle Maintenance',
    'Vending Machine Installer',
    'Void Security Installer',
    'Wall & Door Protection Installer',
    'Wall & Floor Tiler',
    'Warm Roof Insulation Installer',
    'Wastewater Treatment Service Engineer',
    'Water Distribution Systems Engineer',
    'Water Feature & Fountain Installer',
    'Water Hygiene Operative',
    'Water Jetting',
    'Water Mains Layer',
    'Water Meter Installer',
    'Water Services Layer',
    'Water Treatment Equipment Installer',
    'Watermist Fire Suppression Operative',
    'Waterproofing Materials Operative',
    'Weed Spraying & Temporary Barrier Installer',
    'Welding Institute',
    'Wellpoint Dewatering Operative',
    'Wet Pour Rubber Surface Operative',
    'Wheelwrighting',
    'Window & Door Maintenance Operative',
    'Window Control System Installer',
    'Window Films, Graphics & Manifestation Operative',
    'Window Furnishings Installer',
    'Window Opening/closing Equipment Installer',
    'Winter Maintenance Operative',
    'Wood Floor Sanding And Finishing Operative',
    'Wood Preserving & Damp Proofing Technician',
    'Woodmachining',
  ],
};
