export default {
  testCenters: [
    {
      longitude: -2.106246,
      latitude: 57.14693,
      id: 1,
      name: 'Aberdeen Test Centre',
      address: [
        'Ground Floor East Wing',
        'Migvie House',
        '23 North Silver Street',
        'AberdeenUnited Kingdom',
      ],
      postcode: 'AB10 1RJ',
    },
    {
      longitude: -4.081305,
      latitude: 52.415679,
      id: 2,
      name: 'Aberystwyth Test Centre',
      address: [
        'Ground Floor',
        '33 North Parade',
        'Aberystwyth',
      ],
      postcode: 'SY23 2JN',
    },
    {
      longitude: -0.760472,
      latitude: 51.248698,
      id: 3,
      name: 'Aldershot Test Centre',
      address: [
        'Ground Floor',
        '1 London House',
        'Pickford Street',
        'Aldershot',
      ],
      postcode: 'GU11 1TY',
    },
    {
      longitude: -1.479214,
      latitude: 51.20826,
      id: 4,
      name: 'Andover Test Centre',
      address: [
        'Portland House First Floor',
        '55-57 High Street',
        'Andover',
        'United Kingdom',
      ],
      postcode: 'SP10 1LP',
    },
    {
      longitude: -0.837602,
      latitude: 51.820425,
      id: 5,
      name: 'Aylesbury Test Centre',
      address: [
        'Unit 2a',
        'Ground Floor',
        'Midshires Business Park',
        'Smeaton Close',
        'Aylesbury',
        'United Kingdom',
      ],
      postcode: 'HP19 8HL',
    },
    {
      longitude: -4.632138,
      latitude: 55.46215,
      id: 6,
      name: 'Ayr Test Centre',
      address: [
        '1st Floor',
        '7A Boswell Park',
        'Ayr',
      ],
      postcode: 'KA7 1NP',
    },
    {
      longitude: -6.27742,
      latitude: 54.865842,
      id: 7,
      name: 'Ballymena Test Centre',
      address: [
        'Units 3 & 4 The Tower Centre',
        'Wellington Street',
        'Ballymena',
      ],
      postcode: 'BT43 6AH',
    },
    {
      longitude: -4.122429,
      latitude: 53.228553,
      id: 8,
      name: 'Bangor Test Centre',
      address: [
        '331 High Street',
        'Bangor',
        'United Kingdom',
      ],
      postcode: 'LL57 1YA',
    },
    {
      longitude: -4.0624,
      latitude: 51.079988,
      id: 9,
      name: 'Barnstaple Test Centre',
      address: [
        'Riverside Court',
        'Offices - Unit 1 and 4',
        'Castle Street',
        'Barnstaple',
      ],
      postcode: 'EX31 1DR',
    },
    {
      longitude: -3.230865,
      latitude: 54.113484,
      id: 10,
      name: 'Barrow Test Centre',
      address: [
        '111 Duke Street',
        'Barrow in Furness',
      ],
      postcode: 'LA14 1XA',
    },
    {
      longitude: 0.458301,
      latitude: 51.569926,
      id: 11,
      name: 'Basildon Test Centre',
      address: [
        'Southgate House',
        'Suite 1A - First Floor',
        'Town Square',
        'Basildon',
      ],
      postcode: 'SS14 1BN',
    },
    {
      longitude: -5.930962,
      latitude: 54.602368,
      id: 12,
      name: 'Belfast Test Centre',
      address: [
        '1st Floor',
        '119 Royal Avenue',
        'Belfast',
      ],
      postcode: 'BT1 1FF',
    },
    {
      longitude: -2.007874,
      latitude: 55.772608,
      id: 13,
      name: 'Berwick Test Centre',
      address: [
        'William Edgar Building',
        '56-58 Castlegate',
        'Berwick',
      ],
      postcode: 'TD15 1JT',
    },
    {
      longitude: -1.89218,
      latitude: 52.482157,
      id: 14,
      name: 'Birmingham Test Centre',
      address: [
        '38 Dale End',
        'Birmingham',
      ],
      postcode: 'B4 7NJ',
    },
    {
      longitude: -3.022511,
      latitude: 53.854094,
      id: 15,
      name: 'Blackpool Test Centre',
      address: [
        'Darwin Court',
        'Unit 9A Ground Floor',
        'Blackpool Technology Park',
        'Blackpool',
      ],
      postcode: 'FY2 0JN',
    },
    {
      longitude: -2.438751,
      latitude: 53.578677,
      id: 16,
      name: 'Bolton Test Centre',
      address: [
        '34 Queensbrook',
        'Bolton Technology Exchange',
        'Bolton',
      ],
      postcode: 'BL1 4AY',
    },
    {
      longitude: -0.025069,
      latitude: 52.976441,
      id: 17,
      name: 'Boston Test Centre',
      address: [
        '3 Bridge Street',
        'Boston',
        'Lincolnshire',
      ],
      postcode: 'PE21 8QF',
    },
    {
      longitude: -1.877448,
      latitude: 50.720152,
      id: 18,
      name: 'Bournemouth Test Centre',
      address: [
        'Roddis House 3rd floor',
        'Old Christchurch Rd',
        'Bournemouth',
      ],
      postcode: 'BH1 1LG',
    },
    {
      longitude: -1.744368,
      latitude: 53.794505,
      id: 19,
      name: 'Bradford Test Centre',
      address: [
        '1st Floor Caspian House',
        '61 East parade',
        'Bradford',
      ],
      postcode: 'BD1 5EP',
    },
    {
      longitude: -3.575201,
      latitude: 51.504797,
      id: 20,
      name: 'Bridgend Test Centre',
      address: [
        '2nd Floor',
        'Brackla House',
        'Brackla Street',
        'Bridgend',
      ],
      postcode: 'CF31 1BZ',
    },
    {
      longitude: -0.149485,
      latitude: 50.832671,
      id: 21,
      name: 'Brighton Test Centre',
      address: [
        'Citygate - Ground Floor',
        '185 Dyke Road',
        'Hove',
        'Brighton',
      ],
      postcode: 'BN3 1TL',
    },
    {
      longitude: -2.540924,
      latitude: 51.502807,
      id: 22,
      name: 'Bristol Test Centre',
      address: [
        'Building 340',
        'The Crescent',
        'Bristol Business Park',
        'Bristol',
      ],
      postcode: 'BS16 1EJ',
    },
    {
      longitude: -3.401737,
      latitude: 52.149574,
      id: 23,
      name: 'Builth Wells Test Centre',
      address: [
        'Rear of Spar Buildings',
        'Groe Street',
        'Builth Well',
      ],
      postcode: 'LD2 3BL',
    },
    {
      longitude: 0.715023,
      latitude: 52.245395,
      id: 24,
      name: 'Bury St. Edmunds Test Centre',
      address: [
        '2nd Floor St Edmunds House',
        'Lower Baxter Street',
        'Bury St Edmunds',
      ],
      postcode: 'IP33 1ET',
    },
    {
      longitude: 0.122863,
      latitude: 52.204697,
      id: 25,
      name: 'Cambridge Test Centre',
      address: [
        'Second Floor',
        'St Andrews House 59',
        'St.Andrews Street',
        'Cambridge',
      ],
      postcode: 'CB2 3BZ',
    },
    {
      longitude: 1.084784,
      latitude: 51.277641,
      id: 26,
      name: 'Canterbury Test Centre',
      address: [
        'Suite CInvicta House',
        'Lower Bridge Street',
        'Canterbury',
      ],
      postcode: 'CT1 2LG',
    },
    {
      longitude: -3.17128,
      latitude: 51.48105,
      id: 27,
      name: 'Cardiff Test Centre',
      address: [
        '3rd Floor Limerick House',
        'Churchill Way',
        'Cardiff',
      ],
      postcode: 'CF10 2HE',
    },
    {
      longitude: -2.932754,
      latitude: 54.893323,
      id: 28,
      name: 'Carlisle Test Centre',
      address: [
        '10a Lowther Street',
        'Carlisle',
      ],
      postcode: 'CA3 8DA',
    },
    {
      longitude: 0.517849,
      latitude: 51.383065,
      id: 29,
      name: 'Chatham Test Centre',
      address: [
        '14-16 High Street',
        'Chatham',
      ],
      postcode: 'ME4 4EP',
    },
    {
      longitude: 0.472868,
      latitude: 51.731557,
      id: 30,
      name: 'Chelmsford Test Centre',
      address: [
        '1st Floor Grosvenor House',
        '51 New London Rd',
        'Chelmsford',
      ],
      postcode: 'CM2 0ND',
    },
    {
      longitude: -2.072828,
      latitude: 51.901551,
      id: 31,
      name: 'Cheltenham Test Centre',
      address: [
        '18 to 20 Albion Street',
        'Cheltenham',
      ],
      postcode: 'GL52 2LP',
    },
    {
      longitude: -2.883177,
      latitude: 53.193332,
      id: 32,
      name: 'Chester Test Centre',
      address: [
        '1 Corbridge House',
        'The Square Seller Street',
        'Chester',
      ],
      postcode: 'CH1 3AN',
    },
    {
      longitude: -1.422921,
      latitude: 53.236767,
      id: 33,
      name: 'Chesterfield Test Centre',
      address: [
        'Suite 1B',
        '1st Floor 6-8 Corporation St',
        'Chesterfield',
      ],
      postcode: 'S41 7TP',
    },
    {
      longitude: -0.778897,
      latitude: 50.840484,
      id: 34,
      name: 'Chichester Test Centre',
      address: [
        'Metro House',
        'Northgate',
        'Chichester',
      ],
      postcode: 'PO19 1BE',
    },
    {
      longitude: -4.405154,
      latitude: 55.906421,
      id: 35,
      name: 'Clydebank Test Centre',
      address: [
        '1st floor',
        'Erskine House',
        'North Avenue',
        'Clydebank',
      ],
      postcode: 'G81 2DR',
    },
    {
      longitude: 0.894702,
      latitude: 51.885722,
      id: 36,
      name: 'Colchester Test Centre',
      address: [
        'Wellington House',
        'Butt Road',
        'Colchester',
      ],
      postcode: 'CO3 3DA',
    },
    {
      longitude: -0.650087,
      latitude: 52.491241,
      id: 37,
      name: 'Corby Test Centre',
      address: [
        'Corby Innovation Hub',
        'Bangrave Road South',
        'Corby',
        'United Kingdom',
      ],
      postcode: 'NN17 1NN',
    },
    {
      longitude: -1.514548,
      latitude: 52.404727,
      id: 38,
      name: 'Coventry Test Centre',
      address: [
        '1st Floor Warwick Gate',
        '21 - 22 Warwick Row',
        'Coventry',
      ],
      postcode: 'CV1 1ET',
    },
    {
      longitude: -0.188322,
      latitude: 51.112777,
      id: 39,
      name: 'Crawley Test Centre',
      address: [
        'First floor Belgrave House',
        'Station Way',
        'Crawley',
      ],
      postcode: 'RH10 1HU',
    },
    {
      longitude: -0.097997,
      latitude: 51.373684,
      id: 40,
      name: 'Croydon Test Centre',
      address: [
        'Second Floor - Central House',
        '27 Park Street',
        'Croydon',
        'London',
      ],
      postcode: 'CR0 1YD',
    },
    {
      longitude: -1.47213,
      latitude: 52.929629,
      id: 41,
      name: 'Derby Test Centre',
      address: [
        'Ground Floor East Wing',
        '100 Mansfield Road',
        'Derby',
      ],
      postcode: 'DE1 3TT',
    },
    {
      longitude: -1.131193,
      latitude: 53.523499,
      id: 42,
      name: 'Doncaster Test Centre',
      address: [
        'Office 6 Silver House',
        'Silver Street',
        'Doncaster',
      ],
      postcode: 'DN1 1HL',
    },
    {
      longitude: -2.084448,
      latitude: 52.508328,
      id: 43,
      name: 'Dudley Test Centre',
      address: [
        'Trafalgar House',
        '47 -49 King Street',
        'Dudley',
      ],
      postcode: 'DY2 8PS',
    },
    {
      longitude: -3.609742,
      latitude: 55.065635,
      id: 44,
      name: 'Dumfries Test Centre',
      address: [
        '77 - 79 Whitesands Unit 3',
        'Dumfries',
      ],
      postcode: 'DG1 2RX',
    },
    {
      longitude: -2.967273,
      latitude: 56.460765,
      id: 45,
      name: 'Dundee Test Centre',
      address: [
        'Unit 214 - 22 Exchange St',
        'Dundee',
      ],
      postcode: 'DD1 3DE',
    },
    {
      longitude: 0.295166,
      latitude: 50.771522,
      id: 46,
      name: 'Eastbourne Test Centre',
      address: [
        'Senlac House',
        'Ground Floor',
        '53-58 Seaside',
        'Eastbourne',
      ],
      postcode: 'BN22 7NE',
    },
    {
      longitude: -3.186216,
      latitude: 55.958359,
      id: 47,
      name: 'Edinburgh Test Centre',
      address: [
        'Suite 2',
        '2nd floor',
        'Cairncross House',
        '25 Union Street',
        'Edinburgh',
      ],
      postcode: 'EH1 3LR',
    },
    {
      longitude: -3.29417,
      latitude: 57.637119,
      id: 48,
      name: 'Elgin Test Centre',
      address: [
        '2 Southfield Drive(Near Linkwood Medical Centre)',
        'Elgin',
      ],
      postcode: 'IV30 6GR',
    },
    {
      longitude: -3.528261,
      latitude: 50.726916,
      id: 49,
      name: 'Exeter Test Centre',
      address: [
        '3rd Floor',
        'North Suite',
        'Beaufort House',
        'New North Road',
        'Exeter',
      ],
      postcode: 'EX4 4EP',
    },
    {
      longitude: -1.185864,
      latitude: 50.852738,
      id: 50,
      name: 'Fareham Test Centre',
      address: [
        'Thackery House',
        '189-199 West Street',
        'Fareham',
      ],
      postcode: 'PO16 0EN',
    },
    {
      longitude: -5.10578,
      latitude: 56.822151,
      id: 51,
      name: 'Fort William Test Centre',
      address: [
        'The Nevis Centre (off Camanachd Cres)',
        'An Aird',
        'Fort William',
      ],
      postcode: 'PH33 6AN',
    },
    {
      longitude: -2.308378,
      latitude: 51.229314,
      id: 52,
      name: 'Frome Test Centre',
      address: [
        '2 Baywell House (under archway)',
        'Tucker Close',
        'Frome',
      ],
      postcode: 'BA11 5LS',
    },
    {
      longitude: -5.68469,
      latitude: 57.711402,
      id: 53,
      name: 'Gairloch Test Centre',
      address: [
        'Harbour Gairloch',
        'Gairloch',
      ],
      postcode: 'IV21 2BQ',
    },
    {
      longitude: -2.810114,
      latitude: 55.617107,
      id: 54,
      name: 'Galashiels Test Centre',
      address: [
        '45 High Street',
        'Galashiels',
        'Scottish Border',
      ],
      postcode: 'TD1 1RY',
    },
    {
      longitude: -4.26919,
      latitude: 55.858243,
      id: 55,
      name: 'Glasgow Test Centre',
      address: [
        'Suite 325',
        'Pentagon Centre',
        '36-38 Washington Street',
        'Glasgow',
      ],
      postcode: 'G3 8AZ',
    },
    {
      longitude: -2.239997,
      latitude: 51.862659,
      id: 56,
      name: 'Gloucester Test Centre',
      address: [
        'Eastgate House121 - 131 Eastgate Street',
        'Gloucester',
      ],
      postcode: 'GL1 1PX',
    },
    {
      longitude: -0.643422,
      latitude: 52.911525,
      id: 57,
      name: 'Grantham Test Centre',
      address: [
        '1st Floor',
        'The George Centre',
        'Guildhall Street',
        'Grantham',
      ],
      postcode: 'NG31 6NJ',
    },
    {
      longitude: -4.744192,
      latitude: 55.942662,
      id: 58,
      name: 'Greenock Test Centre',
      address: [
        'Ground Floor Victory Court',
        'Cartsburn Maritime',
        'Arthur Street',
        'Greenock',
      ],
      postcode: 'PA15 4RT',
    },
    {
      longitude: -0.080613,
      latitude: 53.562399,
      id: 59,
      name: 'Grimsby Test Centre',
      address: [
        'Unit 8/9Acorn Business Park',
        'Moss Road',
        'Grimsby',
      ],
      postcode: 'DN32 0LW',
    },
    {
      longitude: -0.572354,
      latitude: 51.236708,
      id: 60,
      name: 'Guildford Test Centre',
      address: [
        '71 North Street',
        'Guildford',
      ],
      postcode: 'GU1 4AW',
    },
    {
      longitude: 0.13259,
      latitude: 51.790808,
      id: 61,
      name: 'Harlow Test Centre',
      address: [
        'Cambridge House',
        '1st Floor',
        'Suite 1Cambridge Road',
        'Harlow',
      ],
      postcode: 'CM20 2EQ',
    },
    {
      longitude: -1.539856,
      latitude: 53.988474,
      id: 62,
      name: 'Harrogate Test Centre',
      address: [
        '1st Floor Strayside House',
        '27 West park',
        'Harrogate',
      ],
      postcode: 'HG1 1BJ',
    },
    {
      longitude: 0.549372,
      latitude: 50.868539,
      id: 63,
      name: 'Hastings Test Centre',
      address: [
        'Philips House',
        'Drury Lane',
        'Ponswood Industrial Estate',
        'St Leonards on Sea',
      ],
      postcode: 'TN38 9BA',
    },
    {
      longitude: -4.970019,
      latitude: 51.801623,
      id: 64,
      name: 'Haverfordwest Test Centre',
      address: [
        'First Floor Offices',
        '34 High Street',
        'Haverfordwest',
      ],
      postcode: 'SA61 2DA',
    },
    {
      longitude: -3.664306,
      latitude: 58.116928,
      id: 65,
      name: 'Helmsdale Test Centre',
      address: [
        'Car Park',
        'Couper ParkHighlands',
        'Helmsdal',
      ],
      postcode: 'KW8 6HH',
    },
    {
      longitude: -2.717204,
      latitude: 52.055361,
      id: 66,
      name: 'Hereford Test Centre',
      address: [
        'Ground Floor Penn House9-10 Broad Street',
        'Hereford',
      ],
      postcode: 'HR4 9AP',
    },
    {
      longitude: -0.162966,
      latitude: 51.171076,
      id: 166,
      name: 'Horley Test Centre',
      address: [
        '77 Victoria Road',
        'Horley',
        'Surrey',
      ],
      postcode: 'RH6 7QH',
    },
    {
      id: 67,
      longitude: -1.783232,
      latitude: 53.643983,
      name: 'Huddersfield Test Centre',
      address: [
        '5th FloorRamsden House',
        'New Street',
        'Huddersfiel',
      ],
      postcode: 'HD1 2TW',
    },
    {
      longitude: -0.339164,
      latitude: 53.746129,
      id: 68,
      name: 'Hull Test Centre',
      address: [
        'Unit 14/15 Kingston House SouthBond Street',
        'Hull',
      ],
      postcode: 'HU1 3EN',
    },
    {
      longitude: -2.788803,
      latitude: 57.443759,
      id: 69,
      name: 'Huntly Test Centre',
      address: [
        'Market Muir Car Park',
        'Huntly',
      ],
      postcode: 'AB54 8ET',
    },
    {
      longitude: 0.077031,
      latitude: 51.559921,
      id: 70,
      name: 'Ilford Test Centre',
      address: [
        '253 High road',
        'Ilford',
        'Essex',
        'London',
      ],
      postcode: 'IG1 1NE',
    },
    {
      longitude: -4.22611,
      latitude: 57.477897,
      id: 71,
      name: 'Inverness Test Centre',
      address: [
        '2nd Floor',
        '1-3 Church Street',
        'Inverness',
      ],
      postcode: 'IV1 1DY',
    },
    {
      longitude: 1.147388,
      latitude: 52.059719,
      id: 72,
      name: 'Ipswich Test Centre',
      address: [
        'Hubbard House',
        'Second Floor',
        '6 Civic Drive',
        'Ipswich',
      ],
      postcode: 'IP1 2QA',
    },
    {
      longitude: -5.157958,
      latitude: 55.575968,
      id: 73,
      name: 'Isle of Arran Test Centre',
      address: [
        'Auchrannie Country House & Hotel Brodick',
        'Isle of Arran',
      ],
      postcode: 'KA27 8BZ',
    },
    {
      longitude: -7.370998,
      latitude: 57.47121,
      id: 74,
      name: 'Isle of Benbecula Test Centre',
      address: [
        'Caladh Trust Centre',
        'Balivanich East Camp',
        'Balivanich',
        'Isle of Benbecul',
      ],
      postcode: 'HS7 5LA',
    },
    {
      longitude: -6.280342,
      latitude: 55.757018,
      id: 75,
      name: 'Isle of Islay Test Centre',
      address: [
        'Argyll & Bute Councils Service Point',
        'Jamieson Street',
        'BowmoreIsle of Islay',
      ],
      postcode: 'PA43 7HZ',
    },
    {
      longitude: -5.698747,
      latitude: 56.465958,
      id: 76,
      name: 'Isle of Mull Test Centre',
      address: [
        'Mull and Iona Community Trust',
        'An Roth Community Enterprise Centre',
        'CraignureIsle of Mull',
      ],
      postcode: 'PA65 6AY',
    },
    {
      longitude: -6.307864,
      latitude: 49.916147,
      id: 77,
      name: 'Isle of Scilly Test Centre',
      address: [
        'Porthmellon Enterprise Centreporthmellon Business Park',
        'St MarysIsles of Scilly',
      ],
      postcode: 'TR21 0JY',
    },
    {
      longitude: -1.295054,
      latitude: 50.700857,
      id: 78,
      name: 'Isle of Wight Test Centre',
      address: [
        '48 Lugley Street',
        'NewportIsle of Wight',
      ],
      postcode: 'PO30 5HD',
    },
    {
      longitude: -2.742656,
      latitude: 54.330979,
      id: 79,
      name: 'Kendal Test Centre',
      address: [
        'Office Suites 250 Kentgate Place',
        'Kendal',
      ],
      postcode: 'LA9 6EQ',
    },
    {
      longitude: 0.396139,
      latitude: 52.757937,
      id: 80,
      name: 'Kings Lynn Test Centre',
      address: [
        'Mission HallShip Lane',
        'Off St.Anns Street',
        'Kings Lynn',
      ],
      postcode: 'PE30 1LT',
    },
    {
      longitude: -0.306198,
      latitude: 51.407328,
      id: 81,
      name: 'Kingston Test Centre',
      address: [
        '4th floor Drapers Court',
        'Kingston Hall Road',
        'Kingston Upon Thames',
        'London',
      ],
      postcode: 'KT1 2BQ',
    },
    {
      longitude: -2.973594,
      latitude: 58.982471,
      id: 82,
      name: 'Kirkwall Test Centre',
      address: [
        'THE PICKAQUOY CENTRE(Sports',
        'Arts',
        'Leisure',
        'Conf. Facility)Muddisdale Road',
        'Kirkwall',
      ],
      postcode: 'KW15 1LR',
    },
    {
      longitude: -5.716128,
      latitude: 57.28028,
      id: 83,
      name: 'Kyle of Lochalsh Test Centre',
      address: [
        'Lochalsh Hotel - Ferry RoadRoss Shire',
        'Kyle of Lochalsh',
      ],
      postcode: 'IV40 8AF',
    },
    {
      longitude: -1.547686,
      latitude: 53.799415,
      id: 84,
      name: 'Leeds Test Centre',
      address: [
        'Gallery House 1st FloorThe Headrow',
        'Leeds',
      ],
      postcode: 'LS1 5RD',
    },
    {
      longitude: -1.128364,
      latitude: 52.635474,
      id: 85,
      name: 'Leicester Test Centre',
      address: [
        'Suite 3A Third FloorRutland Centre56 Halford Street',
        'Leicester',
      ],
      postcode: 'LE1 1TQ',
    },
    {
      longitude: -1.148257,
      latitude: 60.152924,
      id: 86,
      name: 'Lerwick Test Centre',
      address: [
        'Islesburgh Community CentreKing Harald St',
        'Lerwick',
      ],
      postcode: 'ZE1 0EQ',
    },
    {
      longitude: -0.539236,
      latitude: 53.229542,
      id: 87,
      name: 'Lincoln Test Centre',
      address: [
        '1st Floor',
        'Queensgate House12 Silver St',
        'Lincoln',
      ],
      postcode: 'LN2 1DY',
    },
    {
      longitude: -2.993661,
      latitude: 53.406869,
      id: 88,
      name: 'Liverpool Test Centre',
      address: [
        'Lower Ground Floor5 Covent Gardens',
        'Liverpool',
      ],
      postcode: 'L2 8UD',
    },
    {
      longitude: -7.324454,
      latitude: 55.000271,
      id: 89,
      name: 'Londonderry Test Centre',
      address: [
        'Ground Floor City Factory',
        '19 Queen Street',
        'Londonderry',
      ],
      postcode: 'BT48 7EF',
    },
    {
      longitude: 1.751741,
      latitude: 52.473993,
      id: 90,
      name: 'Lowestoft Test Centre',
      address: [
        '2nd Floor Waveney Chambers',
        '3-7 Waveney Road',
        'Lowestoft',
      ],
      postcode: 'NR32 1BN',
    },
    {
      longitude: -0.421763,
      latitude: 51.882209,
      id: 91,
      name: 'Luton Test Centre',
      address: [
        '70 - 78 Collingdon Street',
        'Luton',
      ],
      postcode: 'LU1 1RX',
    },
    {
      longitude: 0.225051,
      latitude: 51.557459,
      id: 92,
      name: 'Manchester Test Centre',
      address: [
        'Blue Zone',
        'Ground Floor',
        'Suites 3 & 4Universal Square',
        'Devonshire Street North',
        'Manchester',
      ],
      postcode: 'RM12 6JH',
    },
    {
      longitude: -1.203188,
      latitude: 53.145313,
      id: 93,
      name: 'Mansfield Test Centre',
      address: [
        'Ground Floor',
        'Nth Notts Business Centre',
        '32-34 Rosemary Street',
        'Mansfield',
      ],
      postcode: 'NG18 1QL',
    },
    {
      longitude: -3.378717,
      latitude: 51.746635,
      id: 94,
      name: 'Merthyr Tydfil Test Centre',
      address: [
        '1st Floor Castle House',
        'Glebeland Street',
        'Merthyr',
        'Tydfil',
      ],
      postcode: 'CF47 8AT',
    },
    {
      longitude: -1.229655,
      latitude: 54.575172,
      id: 95,
      name: 'Middlesbrough Test Centre',
      address: [
        'Cleveland Business Centre',
        'Watson Street',
        'Middlesbrough',
      ],
      postcode: 'TS1 2RQ',
    },
    {
      longitude: -0.027266,
      latitude: 51.51453,
      id: 96,
      name: 'Mile End C Test Centre',
      address: [
        '3 Quebec Wharf14 Thomas Road',
        'Limehouse B',
        'London',
      ],
      postcode: 'E14 7AF',
    },
    {
      longitude: -0.767697,
      latitude: 52.038501,
      id: 97,
      name: 'Milton Keynes Test Centre',
      address: [
        'Ground Floor',
        '249 Midsummer Boulevard',
        'Milton Keynes',
      ],
      postcode: 'MK9 1DP',
    },
    {
      longitude: -1.688396,
      latitude: 55.154193,
      id: 98,
      name: 'Morpeth Test Centre',
      address: [
        'Unit 7Telford Court',
        'Loansdean',
        'Morpeth',
      ],
      postcode: 'NE61 2DB',
    },
    {
      longitude: -1.612294,
      latitude: 54.970045,
      id: 99,
      name: 'Newcastle Upon Tyne Test Centre',
      address: [
        'Collingwood House',
        '3 Collingwood Street',
        'Newcastle Upon Tyne',
      ],
      postcode: 'NE1 1JW',
    },
    {
      longitude: -2.992499,
      latitude: 51.590944,
      id: 100,
      name: 'Newport (Gwent) Test Centre',
      address: [
        '6th Floor Clarence House (South Wales)Clarence Place',
        'Newport (Gwent)',
      ],
      postcode: 'NP19 7AA',
    },
    {
      longitude: -1.295054,
      latitude: 50.700857,
      id: 101,
      name: 'Newport (Isle of Wight) Test Centre',
      address: [
        '48 Lugley Street',
        'Newport (Isle of Wight)',
      ],
      postcode: 'PO30 5HD',
    },
    {
      longitude: -6.337795,
      latitude: 54.172841,
      id: 102,
      name: 'Newry Test Centre',
      address: [
        '2nd floor12 John Mitchel Place',
        'Newry',
      ],
      postcode: 'BT34 2BP',
    },
    {
      longitude: -1.441205,
      latitude: 54.350185,
      id: 103,
      name: 'Northallerton Test Centre',
      address: [
        'First Floor',
        'Carrick House',
        'Thurston Road',
        'Northallerton',
      ],
      postcode: 'DL6 2NA',
    },
    {
      longitude: -0.899066,
      latitude: 52.237587,
      id: 104,
      name: 'Northampton Test Centre',
      address: [
        'Suite 201 Sol House',
        "29 St.Katherine's Street",
        'Northampton',
      ],
      postcode: 'NN1 2QZ',
    },
    {
      longitude: 1.299825,
      latitude: 52.629254,
      id: 105,
      name: 'Norwich Test Centre',
      address: [
        '1st Floor',
        '11 Prince of Wales Road',
        'Norwich',
      ],
      postcode: 'NR1 1BD',
    },
    {
      longitude: -1.150741,
      latitude: 52.950951,
      id: 106,
      name: 'Nottingham Test Centre',
      address: [
        'Chiltern House',
        'Castle Gate',
        'Nottingham',
      ],
      postcode: 'NG1 7AR',
    },
    {
      longitude: -5.477035,
      latitude: 56.419171,
      id: 107,
      name: 'Oban Test Centre',
      address: [
        'Corran Halls',
        'No. 54 The Esplanade',
        'Oban',
      ],
      postcode: 'PA34 5AB',
    },
    {
      longitude: -2.100368,
      latitude: 53.540675,
      id: 108,
      name: 'Oldham Test Centre',
      address: [
        '5A - 6A Whitney Court Southlink Business Park',
        'Hamilton Street',
        'Oldham',
      ],
      postcode: 'OL4 1DE',
    },
    {
      longitude: -7.299178,
      latitude: 54.600149,
      id: 109,
      name: 'Omagh Test Centre',
      address: [
        'Anderson House',
        '41 Market Street',
        'Omagh',
      ],
      postcode: 'BT78 1EE',
    },
    {
      longitude: -1.256745,
      latitude: 51.747544,
      id: 110,
      name: 'Oxford Test Centre',
      address: [
        'Top Floor (Buzzer 9)',
        '58 St Aldates',
        'Oxford',
      ],
      postcode: 'OX1 1ST',
    },
    {
      longitude: -5.536625,
      latitude: 50.119246,
      id: 111,
      name: 'Penzance Test Centre',
      address: [
        'Knights Warehouse Bread Street(Corner of Bread St & Belgravia St)',
        'Penzance Cornwall',
      ],
      postcode: 'TR18 2EQ',
    },
    {
      longitude: -0.236499,
      latitude: 52.574902,
      id: 112,
      name: 'Peterborough Test Centre',
      address: [
        'Pearson Professional Centre',
        'Ground Floor',
        'East Wing',
        'Stuart House',
        'St Johns Street',
        'Peterborough',
      ],
      postcode: 'PE1 5DD',
    },
    {
      longitude: -3.734706,
      latitude: 56.702932,
      id: 113,
      name: 'Pitlochry Test Centre',
      address: [
        'Fishers Hotel75 -79 Atholl Road',
        'Pitlochry',
      ],
      postcode: 'PH16 5BN',
    },
    {
      longitude: -4.142755,
      latitude: 50.368716,
      id: 114,
      name: 'Plymouth Test Centre',
      address: [
        'Princess Court',
        '1st FloorPrincess Street',
        'Plymouth',
      ],
      postcode: 'PL1 2EX',
    },
    {
      longitude: -6.446298,
      latitude: 54.420783,
      id: 115,
      name: 'Portadown Test Centre',
      address: [
        'Lismore House23 Church Street',
        'Portadown',
      ],
      postcode: 'BT62 3LN',
    },
    {
      longitude: -6.208217,
      latitude: 57.416571,
      id: 116,
      name: 'Portree Test Centre',
      address: [
        'CO-OP Supermarket',
        'Dunvegan Road',
        'Portree',
      ],
      postcode: 'IV51 9HQ',
    },
    {
      longitude: -1.09014,
      latitude: 50.79777,
      id: 117,
      name: 'Portsmouth Test Centre',
      address: [
        'Ground Floor Annex - Enterprise House',
        'Ismbard Brunel Road',
        'Portsmouth',
      ],
      postcode: 'PO1 2AF',
    },
    {
      longitude: -2.700034,
      latitude: 53.757184,
      id: 118,
      name: 'Preston Test Centre',
      address: [
        'Ground Floor Norwest Court',
        'Guildhall Street',
        'Preston',
      ],
      postcode: 'PR1 3NU',
    },
    {
      longitude: -0.964457,
      latitude: 51.45311,
      id: 119,
      name: 'Reading Test Centre',
      address: [
        '2nd FloorHavell House',
        '62-66 Queens Road',
        'Reading',
      ],
      postcode: 'RG1 4AZ',
    },
    {
      longitude: -1.939723,
      latitude: 52.309141,
      id: 120,
      name: 'Redditch Test Centre',
      address: [
        'Second Floor Grosvenor House',
        'Prospect Hill',
        'Redditch',
      ],
      postcode: 'B97 4DL',
    },
    {
      longitude: -3.490397,
      latitude: 53.319295,
      id: 121,
      name: 'Rhyl Test Centre',
      address: [
        'Pearson Centre - Rhyl3 Bodfor Street',
        'Rhyl',
      ],
      postcode: 'LL18 1AS',
    },
    {
      longitude: -2.291434,
      latitude: 53.470648,
      id: 122,
      name: 'Salford Test Centre',
      address: [
        'Unit 1A Ground Floor',
        'Sovereign Point',
        '31 The Quays',
        'Salford Quays',
        'Salford',
      ],
      postcode: 'M50 3AX',
    },
    {
      longitude: -1.796502,
      latitude: 51.073492,
      id: 123,
      name: 'Salisbury Test Centre',
      address: [
        'Suite 104',
        'Ground Floor',
        'Warner House',
        '123 Castle Street',
        'Salisbury',
      ],
      postcode: 'SP1 3TB',
    },
    {
      longitude: -0.399004,
      latitude: 54.282227,
      id: 124,
      name: 'Scarborough Test Centre',
      address: [
        'Suite 229-30 St Nicholas Street',
        'Scarborough',
      ],
      postcode: 'YO11 2HF',
    },
    {
      longitude: -0.685653,
      latitude: 53.589499,
      id: 125,
      name: 'Scunthorpe Test Centre',
      address: [
        'Haldenby House (Unit 2A)',
        'Doncaster Road',
        'Scunthorpe',
      ],
      postcode: 'DN15 7DQ',
    },
    {
      longitude: -1.471129,
      latitude: 53.381917,
      id: 126,
      name: 'Sheffield Test Centre',
      address: [
        'Orchard House 3rd floor',
        'Leopold Street',
        'Sheffield',
      ],
      postcode: 'S1 2GY',
    },
    {
      longitude: -2.742697,
      latitude: 52.709113,
      id: 127,
      name: 'Shrewsbury Test Centre',
      address: [
        'Suite 2 Canon Court West',
        'Abbey Lawn',
        'Abbey Foregate',
        'Shrewsbury',
      ],
      postcode: 'SY2 5DE',
    },
    {
      longitude: 0.121654,
      latitude: 51.415336,
      id: 128,
      name: 'Sidcup Test Centre',
      address: [
        'Planwell House',
        'LEFA Business Park',
        'Edgington Way',
        'Sidcup (London)',
      ],
      postcode: 'DA14 5BH',
    },
    {
      longitude: -2.016565,
      latitude: 53.960588,
      id: 129,
      name: 'Skipton Test Centre',
      address: [
        'Suite 3',
        'First floor',
        'High St House (access via Newmarket St)High St',
        'Skipton',
      ],
      postcode: 'BD23 2HU',
    },
    {
      longitude: -0.588683,
      latitude: 51.513823,
      id: 130,
      name: 'Slough Test Centre',
      address: [
        'Brooklands House',
        'Brookland Business Centre',
        'Petersfield Avenue',
        'Slough',
      ],
      postcode: 'SL2 5DY',
    },
    {
      longitude: -1.417504,
      latitude: 50.909274,
      id: 131,
      name: 'Southampton Test Centre',
      address: [
        'SECOND FLOOR SOUTH SUITE',
        'ANGLO CITY HOUSE2-6 SHIRLEY ROAD',
        'SOUTHAMPTON',
      ],
      postcode: 'SO15 3EU',
    },
    {
      longitude: 0.715729,
      latitude: 51.537433,
      id: 132,
      name: 'Southend on Sea Test Centre',
      address: [
        '2nd floor',
        'Dencora Court',
        'Tylers Avenue',
        'Southend on Sea',
      ],
      postcode: 'SS1 2BB',
    },
    {
      longitude: -0.129706,
      latitude: 51.632814,
      id: 133,
      name: 'Southgate Test Centre',
      address: [
        '1st Floor Crown House',
        '47 Chase Side',
        'Southgate (London)',
      ],
      postcode: 'N14 5BP',
    },
    {
      longitude: -3.000011,
      latitude: 53.653529,
      id: 134,
      name: 'Southport Test Centre',
      address: [
        'Suite 6 Gordon House',
        '3-5 Leicester Street',
        'Southport',
      ],
      postcode: 'PR9 0ER',
    },
    {
      longitude: -0.08198,
      latitude: 51.503439,
      id: 135,
      name: 'Southwark Test Centre',
      address: [
        '10 Holyrood Street',
        'London',
        'Southwark',
      ],
      postcode: 'SE1 2EL',
    },
    {
      longitude: -2.735079,
      latitude: 53.453182,
      id: 136,
      name: 'St Helens Test Centre',
      address: [
        '2nd Floor Century House',
        'Hardshaw Street',
        'St Helens',
      ],
      postcode: 'WA10 1QU',
    },
    {
      longitude: -0.504858,
      latitude: 51.434777,
      id: 137,
      name: 'Staines Test Centre',
      address: [
        'First Floor',
        '11/17 Kingston Road',
        'Staines',
      ],
      postcode: 'TW18 4QX',
    },
    {
      longitude: -0.218746,
      latitude: 51.908463,
      id: 138,
      name: 'Stevenage Test Centre',
      address: [
        'Middlesex House',
        'Meadway Technology Park',
        'Rutherford Close',
        'Stevenage',
      ],
      postcode: 'SG1 2EF',
    },
    {
      longitude: -3.947907,
      latitude: 56.110332,
      id: 139,
      name: 'Stirling Test Centre',
      address: [
        'Hillside House Suite 1A',
        'Laurelhill Business Park',
        'Stirling',
      ],
      postcode: 'FK7 9JQ',
    },
    {
      longitude: -2.165957,
      latitude: 53.412533,
      id: 140,
      name: 'Stockport Test Centre',
      address: [
        'Ground Floor Kingsgate',
        'Wellington Road North',
        'Stockport',
      ],
      postcode: 'SK4 1LW',
    },
    {
      longitude: -2.172038,
      latitude: 53.032576,
      id: 141,
      name: 'Stoke on Trent Test Centre',
      address: [
        'Unit 8a Whittle Court',
        'Town Road Business Quarter',
        'Hanley',
        'Stoke on Trent',
      ],
      postcode: 'ST1 2QE',
    },
    {
      longitude: -6.38326,
      latitude: 58.208102,
      id: 142,
      name: 'Stornoway Test Centre',
      address: [
        'Caladh Inn',
        '11 James Street',
        'Stornoway',
      ],
      postcode: 'HS1 2QN',
    },
    {
      longitude: -5.02953,
      latitude: 54.904556,
      id: 143,
      name: 'Stranraer Test Centre',
      address: [
        'The Millennium Centre',
        '75 George Street',
        'Stranraer',
      ],
      postcode: 'DG9 7JP',
    },
    {
      longitude: -1.70512,
      latitude: 52.193748,
      id: 144,
      name: 'Stratford Upon Avon Test Centre',
      address: [
        '2nd Floor',
        'Packwood House',
        'Guild Street',
        'Stratford upon Avon',
      ],
      postcode: 'CV37 6RP',
    },
    {
      longitude: -1.381166,
      latitude: 54.907381,
      id: 145,
      name: 'Sunderland Test Centre',
      address: [
        '2nd Floor Havelock Buildings',
        'High Street West',
        'Sunderland',
      ],
      postcode: 'SR1 1TZ',
    },
    {
      longitude: -1.826226,
      latitude: 52.558551,
      id: 146,
      name: 'Sutton Coldfield Test Centre',
      address: [
        'Ground Floor',
        '31-33 Birmingham Road',
        'Sutton Coldfield',
      ],
      postcode: 'B72 1QE',
    },
    {
      longitude: -3.944746,
      latitude: 51.622618,
      id: 147,
      name: 'Swansea Test Centre',
      address: [
        '2nd Floor Grove House',
        '3 Grove Place',
        'Swansea',
      ],
      postcode: 'SA1 5DF',
    },
    {
      longitude: -1.790011,
      latitude: 51.560157,
      id: 148,
      name: 'Swindon Test Centre',
      address: [
        'Ambrose House',
        'Grd Flr',
        'South Suite',
        '30-33 Milton Road',
        'Swindon',
      ],
      postcode: 'SN1 5JA',
    },
    {
      longitude: -5.411621,
      latitude: 55.863997,
      id: 149,
      name: 'Tarbert Test Centre',
      address: [
        'Templer Arts and Leisure Centre',
        'Harbour Street',
        'Tarbert',
      ],
      postcode: 'PA29 6UD',
    },
    {
      longitude: -3.090057,
      latitude: 51.015012,
      id: 150,
      name: 'Taunton Test Centre',
      address: [
        'Ground Floor',
        'Victoria House',
        'Victoria Street',
        'Taunton',
      ],
      postcode: 'TA1 3FA',
    },
    {
      longitude: -4.417802,
      latitude: 58.476904,
      id: 151,
      name: 'Tongue Test Centre',
      address: [
        'The Tongue Hotel',
        'Tongue',
        'Sutherland',
      ],
      postcode: 'IV27 4XD',
    },
    {
      longitude: -3.531686,
      latitude: 50.467851,
      id: 152,
      name: 'Torquay Test Centre',
      address: [
        'Castle Circus House',
        'Rooms 36 - 38136 Union Street',
        'Torquay',
      ],
      postcode: 'TQ2 5QB',
    },
    {
      longitude: -5.048561,
      latitude: 50.262647,
      id: 153,
      name: 'Truro Test Centre',
      address: [
        'Palace Buildings',
        'Quay Street',
        'Truro',
      ],
      postcode: 'TR1 2HE',
    },
    {
      longitude: 0.25837,
      latitude: 51.125929,
      id: 154,
      name: 'Tunbridge Wells Test Centre',
      address: [
        'Foundation House',
        'Coach and Horses Passage',
        'The Pantiles',
        'Royal',
        'Tunbridge Wells',
      ],
      postcode: 'TN2 5NP',
    },
    {
      longitude: -5.154909,
      latitude: 57.89825,
      id: 155,
      name: 'Ullapool Test Centre',
      address: [
        'Macphail Centre',
        'Ullapool High School',
        'Mill Street',
        'Ullapool',
      ],
      postcode: 'IV26 2UN',
    },
    {
      longitude: -0.481672,
      latitude: 51.546504,
      id: 156,
      name: 'Uxbridge Test Centre',
      address: [
        '5-7 Pantiles Walk',
        'Pavillions Shopping Centre',
        'Uxbridge',
      ],
      postcode: 'UB8 1LP',
    },
    {
      longitude: -0.398773,
      latitude: 51.663681,
      id: 157,
      name: 'Watford Test Centre',
      address: [
        '1st Floor',
        'Cassiobury House',
        '11-19 Station Rd',
        'Watford',
      ],
      postcode: 'WD17 1AP',
    },
    {
      longitude: -2.45544,
      latitude: 50.608553,
      id: 158,
      name: 'Weymouth Test Centre',
      address: [
        'Phoenix HouseSt. Nicholas Street',
        'Weymouth',
      ],
      postcode: 'DT4 8AA',
    },
    {
      longitude: -3.085352,
      latitude: 58.438944,
      id: 159,
      name: 'Wick Test Centre',
      address: [
        'Wick Harbour',
        'End of the Fish Mart',
        'Wick',
      ],
      postcode: 'KW1 5HB',
    },
    {
      longitude: -2.633606,
      latitude: 53.547748,
      id: 160,
      name: 'Wigan Test Centre',
      address: [
        'Ground & Basement',
        '38 - 40 Market Street',
        'Wigan',
      ],
      postcode: 'WN1 1HX',
    },
    {
      longitude: -2.13231,
      latitude: 52.587407,
      id: 161,
      name: 'Wolverhampton Test Centre',
      address: [
        '2nd Floor Derwent House',
        '42-46 Waterloo Road',
        'Wolverhampton',
      ],
      postcode: 'WV1 4XB',
    },
    {
      longitude: -2.220099,
      latitude: 52.194202,
      id: 162,
      name: 'Worcester Test Centre',
      address: [
        '3rd Floor Haswell House',
        'Block B1',
        'St. Nicholas Street',
        'Worcester',
      ],
      postcode: 'WR1 1UN',
    },
    {
      longitude: -0.368753,
      latitude: 50.813089,
      id: 163,
      name: 'Worthing Test Centre',
      address: [
        'Pearson Professional Centres',
        'Unit 2',
        'Chatsworth House',
        '39 Chatsworth Road',
        'Worthing',
      ],
      postcode: 'BN11 1LY',
    },
    {
      longitude: -2.631894,
      latitude: 50.939633,
      id: 164,
      name: 'Yeovil Test Centre',
      address: [
        'The Coach House',
        'Ground Floor',
        'St Nicholas Close',
        'Penn Hill',
        'Yeovil',
      ],
      postcode: 'BA20 1SF',
    },
    {
      longitude: -1.108943,
      latitude: 53.957552,
      id: 165,
      name: 'York Test Centre',
      address: [
        'Stirling House',
        'Station Business Park',
        'Holgate Park Drive',
        'York',
      ],
      postcode: 'YO26 4GB',
    },
  ],
};
